.instruction-wrapper {
    display: flex;
    flex-flow: row;
}
  
.instruction-icon {
    flex: 0 0 auto;
    margin: auto 5px auto 0px;
    width: 36px;
    height: 36px;
}
  
.instruction-title {
    flex: 1 1 auto;
    margin: auto 5px;
    color: #000;
    font-size: 18px;
}

.instruction-tile {
	margin-top: 10px;
	margin-left: 0;
	margin-right: 0;
	margin-bottom: 10px;
	height: 100%;
	box-shadow: 0 0 5px rgba(0,0,0,0.15);
	border: 1px solid rgb(87, 87, 87,0.15);
	border-radius: 5px;
	overflow: hidden;
}

.instruction-tile:hover {
	margin-top: 5px;
	margin-bottom: 15px;
	box-shadow: 0 0 10px rgba(0,0,0,0.15)
}

.instruction-tile-table {
	margin-top: 10px;
	margin-left: 10px;
	margin-right: 10px;
	margin-bottom: 10px;
	box-shadow: 0 0 5px rgba(0,0,0,0.15);
	border: 1px solid rgb(87, 87, 87,0.15);
	border-radius: 5px;
	overflow: hidden;
}

.instruction-tile-table:hover {
	margin-top: 5px;
	margin-bottom: 15px;
	box-shadow: 0 0 10px rgba(0,0,0,0.15)
}
