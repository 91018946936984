.post-wrapper {
    margin: 10px 8px;
    padding: 10px 15px;
    border-radius: 3px;
    box-shadow: 2px 5px 5px rgba(0,0,0, 0.2);
    border: 1px solid rgba(0,0,0, 0.05);
    background-color: white;
}

.post-item-wrapper {
    display: flex;
    flex-flow: row;
    width: 100%;
    border: 1px solid rgba(0,0,0, 0.1);
}

.gallery-icon {
    max-height: 150px;
    margin: 3px 5px;
}

.post-item-icon {
    flex: 0 0 auto;
    margin: 10px 15px 10px 15px;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    border: solid 1px rgba(0,0,0, 0.2);
}
  
.post-item-info {
    width: calc(100% - 80px);
    flex: 1 1 auto;
    margin: 10px 10px 10px 0px;
}

.post-item-info-html {
    width: 95%;
    max-height: 70px;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
}

.post-thumb {
    width: 95%;
    max-height: 240px;
    object-fit: cover;
}

.post-thumb-player {
    object-fit: cover;
}

.post-item-header {
    display: flex;
    flex-flow: row;
    font-size: 15px;
}

.post-item-username {
    flex: 0 0 auto;
    font-size: 15px;
    margin: 5px 10px 10px 0px;
}

.post-item-date {
    flex: 1 1 auto;
    font-size: 12px;
    margin: 4px 10px 10px 0px;
}

.post-item-action {
    flex: 0 0 auto;
    font-size: 12px;
    margin: 5px 10px 10px 0px;
    text-decoration: underline;
}

.post-item-footer {
    display: flex;
    flex-flow: row;
    width: 100%;
    margin: 5px 10px 5px 0px;
}

.post-item-footer-section {
    flex: 1 1 auto;
}

.post-video-player {
    max-width: 95%;
}

.invite-icon {
    width: 48px;
    height: 48px;
}

.invite-title {
    font-size: 12px;
}

.invite-email {
    font-size: 12px;
    margin-right: 36px;
    margin-bottom: 0px;
}

.invite-status {
    font-size: 12px;
    margin-bottom: 0px;
}

.org-post-item-info {
    text-align: left;
    flex: 1 1 auto;
    padding: 10px 10px 10px 10px;
    border: 1px solid rgba(0,0,0, 0.1);
}

.org-post-thumb-player {
    width: 100%;
    object-fit: cover;
}

.org-post-thumb {
    width: 100%;
    max-height: 240px;
    object-fit: cover;
}

.post-detail-modal {
    height: calc(100vh - 180px);
}

.post-detail-overlay {
    height: calc(100vh - 280px);
}

.post-detail-wrapper {
    height: 100%;
    width: 100%;
}

.post-detail-title {
    font-size: 30px;
    margin-bottom: 5px;
}

.post-detail-header {
    width: 100%;
    /* background-color: white; */
}

.post-content-wrapper {
    height: calc(100% - 200px);
    overflow-y: scroll;
}

.post-media-wrapper {
    width: 100%;
    padding-top: 56.25%;
    position: relative;
}

.post-media-child {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.post-content-child {
    margin-bottom: 70px;
}

.post-detail-input {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: rgb(238, 238, 238);
}

.post-comment-editor {
    margin: 10px 10px;
}

.post-comment-btn {
    margin: 0px 20px 5px 0px;
}

.comment-item-wrapper {
    display: flex;
    flex-flow: row;
    width: 100%;
}

.comment-item-icon {
    flex: 0 0 auto;
    margin: 5px 10px;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    border: solid 1px rgba(0,0,0, 0.2);
}
  
.comment-item-info {
    width: calc(100% - 80px);
    flex: 1 1 auto;
    margin: 5px 10px;
}

.comment-item-info-html {
    width: 95%;
    max-height: 70px;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
}

.comment-item-username {
    flex: 0 0 auto;
    font-size: 15px;
    margin: 5px 10px 5px 0px;
}

.comment-item-date {
    flex: 1 1 auto;
    font-size: 12px;
    margin: 5px 10px 5px 0px;
}

.comment-item-action {
    flex: 0 0 auto;
    font-size: 12px;
    margin: 5px 10px 5px 0px;
    text-decoration: underline;
}

.comment-item-footer-section {
    font-size: 12px;
    flex: 1 1 auto;
}

.post-gallery-nav-slide-img {
	width: 100%;
	object-fit: contain;
	height: 400px;
	/* background-color: #A6DC34; */
	background-color: #000;
}

.post-gallery-cover {
	position: absolute;
	width: 100%;
	height: 100%;
	bottom: 0;
	left: 0;
	right: 0;
	margin-right: 0 !important;
	margin-left: 0 !important;
}

.post-gallery-wrapper {
	position: relative;
	top: 0;
    left: 20px;
	margin: 0px;
	background-color: rgba(0,0,0,.5);;
}

.post-gallery-header-title {
	font-size: 30px !important;
	color: white;
	text-transform: uppercase;
	margin-bottom: 8px;
	font-weight: 800;
}

.post-gallery-header-sub-title {
	font-size: 14px !important;
	color: white;
	font-weight: 500;
	text-transform: none;
}

.community-pick-header {
    margin-top: 15px 0px 10px;
    font-size: 24px;
    text-align: center;
}

.community-pick-class {
    padding: 8px 10px;
    margin: 2px 0px;
    border-bottom: 3px solid rgba(255,255,255, 0.5);
    font-size: 18px;
    border-radius: 4px;
    color: white;
    background-color: #6372ff;
}

.community-pick-subclass {
    padding: 5px 20px;
    margin: 2px 0px 2px 15px;
    border: 1px solid rgba(0,0,0, 0.2);
    font-size: 16px;
    border-radius: 4px;
    color: #444;
}

.post-game-item {
    padding: 5px 20px;
    margin: 2px 0px 2px 25px;
    border: 1px solid rgba(0,0,0, 0.2);
    font-size: 16px;
    border-radius: 4px;
    color: #444;
}

.community-picker-divider {
    height: 1px;
    background-color: #444;
    margin: 5px 0px;
}

.post_category {
    font-size: 16px;
    color: #444;
    margin: 5px 0px;
    font-weight: 600;
}

.post-tile-fixed {
	margin-top: 10px;
	margin-left: 10px;
	margin-right: 10px;
	margin-bottom: 10px;
	height: 100%;
	box-shadow: 0 0 5px rgba(0,0,0,0.15);
	border: 1px solid rgb(87, 87, 87,0.15);
    background-color: white;
	border-radius: 5px;
	overflow: hidden;
}

@media (max-width: 1024px) {

}
	
@media (max-width: 768px) {
    
	.post-gallery-header-title {
		font-size: 24px !important;
	}
	
	.post-gallery-header-sub-title {
		font-size: 12px !important;
	}

}
	
@media (max-width: 425px) {

    .post-item-footer {
        margin: 5px 10px 5px 0px;
    }
    
	.post-gallery-nav-slide-img {
		height: 300px;
	}
	
	.post-gallery-wrapper {
		text-align: center;
	}
	
	.post-gallery-header-title {
		font-size: 20px !important;
		margin-bottom: 5px;
	}
	
	.post-gallery-header-sub-title {
		font-size: 10px !important;
	}
    
}