/* Navigation */
.main-landing-nav-wrapper {
    position: relative;
	width: 100%;
    margin-bottom: -64px;
    z-index: 3000;
}

.main-landing-nav-floating {
    position: relative;
    margin-bottom: -64px;
    z-index: 4;
}

.main-landing-header {
	display: flex;
	flex-flow: row;
	width: 100%;
	height: 64px;
}

.main-landing-dropdown-menu {
	position: absolute;
    top: 64px;
    width: 100%;
	z-index: 10;
	background-color: rgba(0,0,0,.8);
}

.main-landing-dropdown-menu a {
	display: block;
	padding: 8px 15px;
	font-size: 16px;
	line-height: 16px;
	font-weight: 700;
	width: 100%;
}

.main-landing-dropdown-menu button {
	display: block;
	color: #ccc;
	padding: 8px 15px;
	font-size: 16px;
	line-height: 16px;
	font-weight: 700;
	width: 100%;
	text-align: left;
}

.main-landing-dropdown-menu .dropdown-li .org-a {
	color: #aaa;
}

.main-landing-dropdown-menu .dropdown-li .org-a-active {
	color: #fff;
	background-color: #C1E729;
	border: none;
}

/* Header */

.main-page-header {
    width: 100%;
    background: rgb(25,224,120);
    background: linear-gradient(180deg, rgba(0,86,143,1) 0%, rgba(25,224,120,1) 100%);
    position: relative;
    padding: 140px 0 120px 0;
    overflow: hidden;
}

.main-page-header-bg {
    background-image: url('./img/world-map-dots.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    opacity: 0.49;
    mix-blend-mode: luminosity;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    transform: scale(1.2);
}

.main-intro {
    position: relative;
	display: table;
	width: 100%;
	padding: 0;
    z-index: 2;
}

.main-intro .overlay {
	background: rgba(0,0,0,0.0);
}

.main-intro h1 {
	font-family: 'Raleway', sans-serif;
	color: #fff;
	font-size: 82px;
	font-weight: 700;
	text-transform: uppercase;
	margin-top: 0px;
	margin-bottom: 30px;
}

.main-intro h1 span {
	font-weight: 800;
	color: #5ca9fb;
}

.main-intro p {
	color: #fff;
	font-size: 22px;
	font-weight: 300;
	line-height: 30px;
	margin: 0 auto;
	margin-bottom: 60px;
}

.main-page-columns {
    padding: 0 15px;
    margin: 0 auto;
    display: flex;
}

.main-page-column-1 {
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    font-family: 'Poppins', sans-serif;
}

.main-page-column-2 {
    width: 50%;
    display: flex;
    padding: 25px 0 25px 0;
}

.main-page-column-2 img {
    width: 100%;
    height: auto;
}

/* Services */
.main-services {
    width: 100%;
    margin: -100px 0 60px 0;
}

.main-services .container {
    display: flex;
    position: relative;
    z-index: 2;
}

.main-services-item {
    flex: 1 1 auto;
    box-shadow: 5px 10px 30px -10px rgb(0 0 0 / 20%);
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin: 0px 20px 0px 0px;
    --e-column-margin-right: 20px;
    --e-column-margin-left: 0px;
    padding: 50px 50px 50px 50px;
    border-radius: 25px 25px 25px 25px;
    background: #fff;
    position: relative;
}

.main-services-title {
    display: flex;
    flex-flow: row;
}

.main-services-icon {
    color: #FF9222;
    font-size: 48px;
    font-weight: 900;
    flex: 0 0 auto;
}

.main-services-number {
    font-size: 32px;
    font-weight: 900;
    flex: 1 1 auto;
    text-align: end;
    padding-top: 10px;
}

.main-services-subtitle {
    font-size: 20px;
    font-weight: 600;
    color: black;
    margin: 10px 0px 15px;
}

.main-services-text {
    font-size: 16px;
    color: #444;
    margin-bottom: 15px;
}

/* Main Feature */
.main-feature {
    width: 100%;
    margin: 40px 0 40px 0;
}

.main-feature .container {
    display: flex;
    position: relative;
    z-index: 2;
}

.main-feature-texts {
    width: 55%;
    padding-right: 60px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
}

.main-feature-title {
    font-size: 42px;
    line-height: 50px;
    font-weight: 700;
    color: #111111;
}

.main-feature-text {
    font-size: 16px;
    line-height: 25px;
    color: #3a3a3a;
    margin: 30px 0;
}

.main-feature ul {
    padding: 0;
    color: #292929;
    font-size: 16px;
}

.main-feature ul li {
    list-style: none;
    margin-bottom: 6px;
    font-weight: 500;
}

.main-feature-item-check {
    font-size: 16px;
    font-weight: 900;
    margin-right: 10px;
    color: #FF9222;
}

.main-feature-thumbnail {
    width: 45%;
    background: url(./img/fundo-sobre.png);
    background-size: cover;
    position: relative;
}

.main-feature-thumbnail img {
    width: 100%;
    height: auto;
}

/* Video Overlay */

.main-video-link {
    position: absolute;
    width: 100px;
    height: 100px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.main-video-link img {
    width: 100px;
    height: 100px;
}

.main-player-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.15);
    z-index: 5000;
}

.main-player-overlay .container {
    height: 100vh;
}

.main-player-overlay .container .close {
    display: flex;
    flex-flow: row;
    margin-top: 7%;
    text-align: end;
}

.main-player-overlay .container .close .button {
    font-size: 30px;
    color: black;
    background-color: white;
    width: 48px;
    height: 48px;
    border-radius: 24px;
    margin-bottom: 10px;
    text-align: center;
}

.main-player-overlay .container iframe {
    width: 100%;
    height: 70%;
}

/* Review */

.main-reviews {
    font-family: "Poppins", Sans-serif;
    padding-top: 90px;
    background-image: url('./img/world-map-dots.png');
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
}

.main-reviews:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    opacity: 0.9;
}

.main-reviews .container {
    max-width: 1280px;
    padding: 0 15px;
    margin: 0 auto 40px;
    position: relative;
    z-index: 2;
}

.main-reviews .container h6 {
    color: #ff9222;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.1em;
    margin-bottom: 15px;
    text-align: center;
}

.main-reviews .container h2 {
    margin: 0px 0px 15px 0px;
    font-size: 42px;
    font-weight: 700;
    line-height: 1.2em;
    text-transform: uppercase;
    text-align: center;
}

.main-reviews .container p {
    color: #3a3a3a;
    text-align: center;
}

.main-review-item-content {
	margin: 10px 10px;
    padding: 8px 15px;
    background-color: white;
	height: 100%;
	box-shadow: 0 0 5px rgba(0,0,0,0.15);
	border: 1px solid rgb(87, 87, 87,0.15);
	border-radius: 5px;
	overflow: hidden;
}

.main-review-item-content p {
    font-style: italic;
    color: #666;
    font-size: 15px;
    line-height: 23px;
    text-align: left;
}

.main-review-item-content p {
    font-style: italic;
    color: #666;
    font-size: 15px;
    line-height: 23px;
}

.main-review-item-content .stars {
    display: inline-block;
    margin-right: 10px;
}

.main-review-item-meta span {
    color: #666;
    font-size: 12px;
    margin: auto 0px;
}

/* Statistics */
.main-statistics {
	padding: 30px 10px;
}

.main-statistics .container {
    background: url(./img/fundo-campeonatos.png) no-repeat left center;
    background-color: #FF9222;
    border-radius: 25px;
    flex-wrap: wrap;
    padding: 30px 20px 40px 20px;
    max-width: 1250px;
}

.main-statistics-description {
	font-size: 15px !important;
	line-height: 26px !important;
    color: white;
}

.main-statistics-icon {
	font-size: 36px;
    color: white;
}

.main-statistics-subtitle {
	font-size: 20px !important;
	line-height: 26px;
	margin-bottom: 0;
    color: white;
}

/* Plans */

.main-plans {
    font-family: "Poppins", Sans-serif;
    padding: 40px 0px;
    background-image: url('./img/world-map-dots.png');
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
}

.main-plans:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    opacity: 0.9;
}

.main-plans .container {
    max-width: 1280px;
    padding: 0 15px;
    margin: 0 auto 40px;
    position: relative;
    z-index: 2;
}

.main-plans .container h2 {
    margin: 0px 0px 15px 0px;
    font-size: 42px;
    font-weight: 700;
    line-height: 1.2em;
    text-transform: uppercase;
    text-align: center;
}

.main-plans .container p {
    color: #3a3a3a;
    text-align: center;
}

/* Statistics */
.main-talk {
	padding: 30px 10px;
}

.main-talk .container {
    font-family: "Poppins", Sans-serif;
    background-color: transparent;
    background-image: radial-gradient(at top left, #0B1061 0%, #087CE3 100%);
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin-bottom: 0px;
    z-index: 1;
    border-radius: 25px;
    padding: 60px 60px 100px 130px;
    display: flex;
    align-items: center;
}

.main-talk .container .avatar {
    width: 210px;
    height: 210px;
    border-radius: 100%;
    border-style: solid;
    border-width: 10px 10px 10px 10px;
    border-color: #FFFFFF12;
    margin-right: 45px;
}

.main-talk .container .avatar img {
    max-width: 100%;
    height: auto;
}

.main-talk .container .info h6 {
    color: #FFE054;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.1em;
}

.main-talk .container .info h3 {
    color: #fff;
    margin: 0px 0px -9px 0px;
    font-size: 42px;
    font-weight: 700;
    line-height: 1.2em;
    margin: 17px 0 3px 0;
}

.main-talk .container .info .desc {
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    color: #d9d9d9;
    margin-bottom: 20px;
}

.main-talk .container .info .botoes {
    display: flex;
    align-items: center;
}

.main-talk .container .info .botoes .whatsapp {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    background-color: #0C9C3E;
    border-style: solid;
    border-width: 0px 0px 0px 0px;
    border-radius: 6px 6px 6px 6px;
    padding: 13px 40px 13px 40px;
    text-transform:uppercase;
    transition: all 0.3s ease-out 0s;
}

.main-talk .container .info .botoes .whatsapp:hover {
    background:#0caf45;
    transition: all 0.3s ease-out 0s;
}

.main-talk .container .info .botoes span {
    font-size: 14px;
    line-height: 24px;
    color: #fff;
    margin:0 12px 0 18px;
    font-weight:600;
    text-transform:uppercase;
}

.main-talk .container .info .botoes a {
    transition: all 0.3s ease-out 0s;
}

.main-talk .container .info .botoes .also {
    width: 32px;
    height: 32px;
    margin-right: 5px;
    background-color: #087CE3;
    border-radius: 16px;
    font-size: 18px;
    text-align: center;
    color: white;
}

.main-talk .container .info .botoes a:hover {
    transform: scale(1.1);
    transition: all 0.3s ease-out 0s;
}

.main-talk .mobile-divder {
    text-align: center;
    margin-top: -80px;
    position: relative;
    z-index: 1;
}

.main-talk .mobile-divder img {
    max-width: 590px;
}

/* Tutorial */
.main-tutorial {
    width: 100%;
    margin: 40px 0 60px 0;
}

.main-tutorial h2 {
    margin: 0px 0px 15px 0px;
    font-size: 42px;
    font-weight: 700;
    line-height: 1.2em;
    text-transform: uppercase;
    text-align: center;
}

.main-tutorial p {
    color: #3a3a3a;
    text-align: center;
}

.main-tutorial .container {
    display: flex;
    position: relative;
    z-index: 2;
}

.main-tutorial-item {
    flex: 1 1 auto;
    margin: 0px 20px 0px 0px;
    --e-column-margin-right: 20px;
    --e-column-margin-left: 0px;
    border-radius: 25px 25px 25px 25px;
    position: relative;
}

.main-tutorial-item img {
    display: block;
    width: 100%;
}

/* Contact Section */
.main-contacts {
	padding: 20px 0 30px 0;
	background-color: #F6F6F6;
	color: rgba(0,0,0,.75);
}
.main-contacts .section-title {
	margin-bottom: 20px;
}
.main-contacts .section-title p {
	font-size: 16px;
}
.main-contacts h2 {
	color: #000;
	margin-top: 10px;
	margin-bottom: 15px;
	padding-bottom: 15px;
}
.main-contacts .section-title h2::after {
	position: absolute;
	content: "";
	background: rgba(0,0,0,.3);
	height: 4px;
	width: 60px;
	bottom: 0;
	left: 30px;
}
.main-contacts h3 {
	color: #000;
	margin-top: 80px;
	margin-bottom: 25px;
	padding-bottom: 20px;
	font-weight: 400;
}
.main-contacts form {
	padding-top: 20px;
}
.main-contacts .text-danger {
	color: #cc0033;
	text-align: left;
}
.main-contacts .btn-custom {
	margin: 30px 0;
	background: transparent;
	border: 2px solid #fff;
}
.main-contacts .btn-custom:hover {
	color: #1f386e;
	background: #fff;
}
.main-contacts .form-control {
	display: block;
	width: 100%;
	padding: 6px 12px;
	font-size: 16px;
	line-height: 1.42857143;
	color: #444;
	background-color: #fff;
	background-image: none;
	border: 1px solid #ddd;
	border-radius: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
	-webkit-transition: none;
	-o-transition: none;
	transition: none;
}
.main-contacts .form-control:focus {
	border-color: #999;
	outline: 0;
	-webkit-box-shadow: transparent;
	box-shadow: transparent;
}
.main-contacts .contact-item {
	margin: 20px 0;
}
.main-contacts .contact-item span {
	color: rgba(0,0,0,1);
	margin-bottom: 10px;
	display: block;
}
.main-contacts .contact-item i.fa {
	margin-right: 10px;
}
.main-contacts .social {
	border-top: 1px solid rgba(0,0,0,0.15);
	padding-top: 10px;
	margin-top: 10px;
	text-align: center;
}
.main-contacts .social ul li {
	display: inline-block;
	margin: 0 10px;
}
.main-contacts .social i.fa {
	font-size: 16px;
	width: 32px;
	height: 32px;
	padding: 6px 0;
	border: 2px solid #333;
	color: #333;
	border-radius: 50%;
	transition: all 0.3s;
}
.main-contacts .social i.fa:hover {
	color: #608dfd;
	background: #333;
}

/* Terms & Policy */
.main-info-area {
    width: 100%;
    background: rgb(25,224,120);
    background: linear-gradient(180deg, rgba(0,86,143,1) 0%, rgba(25,224,120,1) 100%);
    position: relative;
    overflow: hidden;
    height: 100vh;
}

.main-info-area-bg {
    background-image: url('./img/world-map-dots.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    opacity: 0.49;
    mix-blend-mode: luminosity;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    transform: scale(1.2);
}

.main-info-area-content {
    position: relative;
    margin: 90px 0 0px 0;
    height: 100%;
    overflow-y: auto;
    z-index: 3;
}

.main-info-area-content .info h1 {
    color: white;
}

.main-info-area-content .info h2 {
    color: white;
}

.main-info-area-content .info h3 {
    color: white;
}

.main-info-area-content .info h4 {
    color: white;
}

.main-info-area-content .info p {
    color: #eee;
}

.main-info-area-content .info ul {
    color: #eee;
}


@media (max-width: 1024px) {
}
	
@media (max-width: 768px) {
    .main-page-columns {
        display: inherit;
    }
    
    .main-services .container {
        display: inherit;
    }

    .main-services-item {
        margin: 20px 10px;
    }

    .main-feature .container {
        display: inherit;
    }
    
    .main-talk .container .avatar {
        margin: 5px auto;
    }
    
    .main-talk .container .info h6 {
        text-align: center;
    }

    .main-talk .container .info h3 {
        text-align: center;
    }

    .main-talk .container .info .desc {
        text-align: center;
    }
    
    .main-talk .container {
        display: inherit;
        padding: 20px 6px 60px 6px;
    }
    
    .main-talk .container .info .botoes {
        display: inherit;
        text-align: center;
    }

    .main-talk .mobile-divder {
        margin-top: -20px;
    }
    
    .main-talk .mobile-divder img {
        max-width: 80%;
    }
    
    .main-talk .container .info .botoes .whatsapp {
        font-size: 12px;
        border-radius: 6px 6px 6px 6px;
        padding: 6px 4px 6px 4px;
    }

    .main-page-column-1 {
        width: 100%;
        text-align: center;
    }
    
    .main-page-column-2 {
        width: 100%;
    }

    .main-feature-texts {
        width: 100%;
        padding-right: 0px;
    }
    
    .main-feature-thumbnail {
        width: 100%;
    }
    
    .main-tutorial .container {
        display: inherit;
    }
    
    .main-tutorial-item {
        margin: 20px 10px;
    }

    .main-video-link {
        width: 50px;
        height: 50px;
    }

    .main-video-link img {
        width: 50px;
        height: 50px;
    }
}
	
@media (max-width: 425px) {
}