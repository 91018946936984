.chat-layout-hidden {
	position: fixed;
	bottom: 0;
	right: 0px;
	height: 70px;
	overflow: hidden;
}

.chat-layout {
	position: fixed;
	bottom: 0;
	right: 40px;
	height: 500px;
	overflow: hidden;
}

.chat-wrapper {
	display: inline-block;
	background-color: white;
}

.chat-btn {
	display: inline-block;
	font-size: 24px;
	width: 48px;
	height: 48px;
	line-height: 48px;
	text-align: center;
	margin: 10px 20px;
	padding: 0px 10px;
	color: white;
	background-color: #4169E1;
	border-radius: 24px;
	/* border: 1px solid #f4645a; */
	text-transform: uppercase;
	box-shadow: 1px 2px 2px rgba(0,0,0,.05);
}

.chat-title {
	background-color: royalblue;
	color: white;
	height: 50px;
	font-size: 20px;
	font-weight: 500;
	padding: 10px 20px;
}

.chat-messages {
	width: 370px !important;
	height: 400px !important;
	overflow-y: auto;
	border: 1px solid rgb(87, 87, 87,0.15);
}

.chat-input {
	width: 370px;
	margin-bottom: 10px;
	border: 1px solid rgb(87, 87, 87,0.15);
	background-color: white;
}

.chat-list {
    min-width: 240px;
    max-width: 380px;
    overflow: auto;
}

@media (max-width: 1024px) {
    
}
	
@media (max-width: 768px) {
    
}
	
@media (max-width: 425px) {
		
	.chat-layout-hidden {
		height: 50px;
	}

	.chat-layout {
		right: 0px;
		height: 500px;
		overflow: hidden;
	}

	.chat-wrapper {
		display: inline-block;
		background-color: white;
	}

	.chat-btn {
		font-size: 20px;
		width: 48px;
		height: 48px;
		margin: 10px 20px;
	}

	.chat-title {
		height: 50px;
		font-size: 20px;
		font-weight: 500;
		padding: 10px 20px;
	}

	.chat-messages {
		width: 320px;
		height: 380px;
	}

	.chat-input {
		width: 320px;
	}
	
}
