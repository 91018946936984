.popup-bg {
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url(./img/bg_popup_gradient.svg);
	margin-top: 10px;
	margin-left: 10px;
	margin-right: 10px;
	margin-bottom: 10px;
	box-shadow: 0 0 5px rgba(0,0,0,0.15);
	border: 1px solid rgb(87, 87, 87,0.15);
	border-radius: 5px;
	overflow: hidden;
}

.popup-bg:hover {
	margin-top: 5px;
	margin-bottom: 15px;
	box-shadow: 0 0 10px rgba(0,0,0,0.15)
}

.free-popup-bg {
	margin-top: 40px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 10px;
    max-width: 480px;
	box-shadow: 0 0 5px rgba(0,0,0,0.15);
	border: 1px solid rgb(87, 87, 87,0.15);
	border-radius: 5px;
	overflow: hidden;
    background-color: white;
}

.free-popup-bg:hover {
	margin-top: 38px;
	margin-bottom: 12px;
	box-shadow: 0 0 10px rgba(0,0,0,0.15)
}

.popup-icon {
    display: list-item;
    width: 160px;
    height: 160px;
    margin: 20px auto;
}

.popup-icon-small {
    display: list-item;
    width: 80px;
    height: 80px;
    margin: 10px auto;
}

.popup-title {
    margin: 20px auto;
    text-align: center;
    font-size: 24px;
}

.popup-subtitle {
    margin: 15px auto;
    text-align: center;
    font-size: 20px;
}

.popup-description {
    margin: 15px auto;
    text-align: center;
    font-size: 16px;
}

.popup-btn {
    display: list-item;
    margin: 20px auto;
    text-align: center;
    font-size: 20px;
    padding: 10px 40px;
}

.popup-countdown {
	font-size: 18px;
    font-weight: 800;
    color: #FF1F1F;
}

.popup-countdown-label {
    margin-left: 7px;
	font-size: 10px;
    letter-spacing: 1px;
}
