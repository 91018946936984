.side-menu-floating {
	position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,.4);
    display: flex;
    flex-flow: row;
    z-index: 10;
}

.side-menu-pane {
    width: 275px;
    height: 100vh;
    background-attachment: fixed;
    background-color: #051e34;
	background-image: url(./img/nav_nachos.png);
    background-size: contain;
    background-position-y: center;
    background-repeat: no-repeat;
}

.side-menu-header {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0 20px;
    height: 68px;
}

.side-menu-header-icon {
    width: 28px;
    height: 68px;
}

.side-menu-header-title {
    margin: auto 10px;
    font-size: 20px;
    color: white;
    font-weight: 600;
}

.side-menu-item-main {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0 20px;
    height: 48px;
    color: white;
    border-top: 1px solid rgba(255,255,255,.4);
    border-bottom: 1px solid rgba(255,255,255,.4);
}

.side-menu-item {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0 20px;
    color: white;
    height: 48px;
}

.side-menu-item-child {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-left: 40px;
    padding-right: 20px;
    color: white;
    height: 48px;
}

.side-menu-membership {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0 20px;
    color: white;
    height: 64px;
    border-top: 1px solid rgba(255,255,255,.4);
    border-bottom: 1px solid rgba(255,255,255,.4);
}

.side-menu-item-main.selected {
    color: rgb(138,180,248);
}

.side-menu-item.selected {
    color: rgb(138,180,248);
}

.side-menu-item-child.selected {
    color: rgb(138,180,248);
}

.side-menu-membership.selected {
    color: rgb(138,180,248);
}

.side-menu-item-icon {
    width: 28px;
    max-height: 48px;
    margin: auto 0px;
    font-size: 22px;
    font-weight: 400;
}

.side-menu-item-title {
    flex: 1 1 auto;
    margin: auto 10px;
    font-size: 18px;
    font-weight: 400;
}

.side-menu-contents {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.side-menu-items {
    flex: 1 1 auto;
    overflow-y: auto;
}

.side-menu-item-icon {
    width: 28px;
    max-height: 48px;
    margin: auto 0px;
    font-size: 22px;
    font-weight: 400;
}

.side-menu-membership-info {
    flex: 1 1 auto;
    margin: 4px 0px;
}

.side-menu-membership-title {
    width: 100%;
    margin: 5px 0px;
    line-height: 20px;
    font-size: 18px;
    font-weight: 400;
}

.side-menu-membership-date {
    width: 100%;
    margin: 5px 0px;
    line-height: 16px;
    font-size: 14px;
    font-weight: 300;
}

.side-menu-membership-action {
    width: 64px;
    margin: auto 0px;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
}
