.division-detail-header {
    display: flex;
    flex-flow: row;
    margin: 10px 8px;
    padding: 10px 15px;
    border-radius: 3px;
    box-shadow: 2px 5px 5px rgba(0,0,0, 0.2);
    border: 1px solid rgba(0,0,0, 0.05);
    background-color: white;
}

.season-detail-header {
    background-color: white;
    margin: 10px 8px;
    padding: 10px 15px;
    border-radius: 3px;
    box-shadow: 2px 5px 5px rgba(0,0,0, 0.2);
    border: 1px solid rgba(0,0,0, 0.05);
    background-color: white;
}

.division-detail-header-thumb {
    width: 80px;
    height: 80px;
    margin-right: 20px;
}

.division-detail-header-title {
    font-size: 18px;
}

.division-detail-header-info {
    font-size: 14px;
}

.division-mode-btn {
    font-weight: 600;
    background-color: #34BB87;
    margin-right: 25px;
    color: white;
    padding: 5px 20px;
    border-radius: 5px;
}

.division-mode-btn:hover {
    background-color: #34BB87;
    color: white;
}

.division-mode-btn:focus {
    background-color: #34BB87;
    color: white;
}

.division-mode-btn:active {
    background-color: #34BB87;
    color: white;
}

.division-mode-btn-inactive {
    font-weight: 600;
    background-color: #eeeded;
    margin-right: 25px;
    color: #8d8c8c;
    padding: 5px 20px;
    border-radius: 5px;
    border: none;
}

.division-mode-btn-inactive:hover {
    background-color: #CBF4C9;
    color: #34BB87;
    border: none;
}

.division-mode-btn-inactive:focus {
    background-color: #CBF4C9;
    color: #34BB87;
    border: none;
}

.division-mode-btn-inactive:active {
    background-color: #CBF4C9;
    color: #34BB87;
    border: none;
}

.seasons-header {
    padding: 5px 10px;
    margin: 15px 10px;
}

.season-badge {
    width: 100%;
    padding: 10px 0px;
}

.game-team-icon-big {
    width: 80px;
    height: 80px;
    margin: 0px 10px;
    border: 1px solid rgba(0,0,0, 0.2);
}

.game-team-title-big {
    margin: 10px 5px;
    font-size: 17px;
    font-weight: 700;
    text-transform: uppercase;
    color: black;
}

.game-progress-title {
    margin: 30px 5px 0px;
    font-size: 17px;
    font-weight: 700;
    color: black;
    width: 100%;
    text-align: center;
}

.game-score-item {
    display: inline-block;
    width: 50px;
    height: 40px;
    background-color: #194381;
    color: white;
    font-size: 20px;
    line-height: 20px;
    padding: 10px 5px;
    border-radius: 5px;
    margin: 10px 0px;
}

.game-progress-item {
    margin: 5px auto;
    width: 100%;
    max-width: 1024px;
    height: 32px;
    font-size: 18px;
    font-weight: 700;
}

.game-team-icon {
    width: 40px;
    height: 40px;
    margin: 0px 10px;
    border: 1px solid rgba(0,0,0, 0.2);
}

.game-badge {
    margin: 10px 5px;
    padding: 10px 20px;
    box-shadow: 2px 2px 2px rgba(0,0,0, 0.2);
}

.game-badge-calendar {
    width: 50px;
    height: 50px;
    margin: 5px 10px;
    border-radius: 5px;
    background-color: #F6F7FB;
}

.game-badge-day {
    font-size: 22px;
    font-weight: 1000;
    text-align: center;
    color: #788492;
}

.game-badge-month {
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    color: #788492;
    margin-top: -7px;
}

.game-badge-weekday {
    font-size: 17px;
    font-weight: 1000;
    text-align: center;
    padding-top: 5px;
    color: #788492;
}

.game-badge-week {
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    color: #788492;
    margin-top: -2px;
}

.game-badge-time {
    margin-top: 10px;
    font-size: 13px;
    color: black;
}

.game-badge-action {
    margin-top: 10px;
}

.form-setting-wrapper {
    display: flex;
    flex-flow: row;
    margin: 10px 20px;
    border: 1px solid rgba(0,0,0, 0.1);
    border-radius: 3px;
    vertical-align: middle;
    padding: 15px 10px;
}

.form-setting-title {
    flex: 1 1 auto;
    margin-top: 5px;
    margin-right: 5px;
}

.form-setting-input {
    flex: 2 2 auto;
    margin-right: 5px;
    font-size: 12px;
}

.form-setting-control {
    flex: 0 0 auto;
    margin-right: 5px;
    font-size: 12px;
}

.round-badge-title {
    margin: 20px 5px;
}

.round-badge-timeline {
    display: flex;
    flex-flow: row;
    margin: 10px 20px;
    border: 1px solid rgba(0,0,0, 0.1);
    border-radius: 3px;
    vertical-align: middle;
    padding: 5px 10px;
}

.round-badge-timeline-progress {
    flex: 1 1 auto;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 5px;
}

.round-badge-timeline-control {
    flex: 0 0 auto;
    margin-right: 5px;
    font-size: 24px;
}

.round-badge-timeline-time {
    flex: 0 0 auto;
    margin-right: 5px;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 12px;
}

.round-team-events-header {
    display: flex;
    flex-flow: row;
    margin: 10px 5px;
    border: 1px solid rgba(0,0,0, 0.1);
    border-radius: 3px;
    vertical-align: middle;
    padding: 5px 10px;
}

.round-badge-title {
    font-size: 18px;
}

.round-badge-subtitle {
    font-size: 13px;
}

.game-member-list {
    font-size: 12px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.round-event-icon {
    display: inline-flex;
    font-size: 28px;
    margin-right: 10px;
    margin-top: 5px;
}

.event-badge-card {
    margin: 5px 10px;
    padding: 5px 10px;
    box-shadow: 2px 5px 5px rgba(0,0,0, 0.2);
}

.event-badge-type {
    margin-top: auto;
    margin-bottom: auto;
}

.event-badge-title {
    display: inline-block;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 12px;
    margin: auto 5px;
}

.event-badge-icon {
    width: 25px;
    height: 25px;
    margin: auto 5px;
}

.event-badge-info {
    display: flex;
    flex-flow: row;
    margin: auto 0;
}

.event-badge-type {
    text-align: center;
    flex: 0 0 auto;
    margin: auto 0px;
}

.event-info-group {
    display: flex;
    flex-flow: row;
    margin: auto 0px;
    padding: 5px;
}

.event-info-icon {
    flex: 0 0 auto;
    margin-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    width: 32px;
    height: 24px;
}

.event-info-text {
    flex: 0 0 auto;
    margin-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 12px;
}

.event-edit-wrapper {
    padding: 10px 20px;
}

.player-form-item {
    display: flex;
    flex-flow: row;
    margin: 10px 5px;
}

.player-form-title {
    flex: 0 0 auto;
    margin-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 16px;
    font-weight: 600;
    min-width: 120px;
    text-align: right;
}

.player-form-value {
    flex: 1 1 auto;
    margin-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 16px;
}

.player-form-image {
    max-width: 400px;
    max-height: 300px;
}

.player-form-status-filled {
    flex: 0 0 auto;
    margin-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 16px;
    color: #409C40;
}

.player-form-status-error {
    flex: 0 0 auto;
    margin-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 16px;
    color: #E24646;
}

.player-form-status-warning {
    flex: 0 0 auto;
    margin-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 16px;
    color: #EEB825;
}

.club-member-status {
    margin: 2px 5px;
    font-size: 12px;
    font-weight: 600;
}

.team-member-list {
    margin: 25px 15px 0px;
    font-size: 15px;
    color: #555;
    text-transform: uppercase;
}

.team-thumb {
    width: 50px;
    height: 50px;
    margin: 10px 20px;
}

.stat-table-title {
    padding: 12px 20px;
    font-size: 16px;
    font-weight: 800;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    margin-bottom: 0px;
}

.championship-nav {
    padding: 0px;
}

.champions-divider {
    padding-top: 20px;
    width: 100%;
    height: 1px;
}

.championship-header {
    display: flex;
    flex-flow: row;
    margin: 0px;
    padding: 0px;
    background-color: white;
	border: 1px solid rgba(0, 0, 0, 0.05);
}

.championship-header-icon {
	width: 30px;
	height: 30px;
	margin: auto 10px;
	border-radius: 2px;
    object-fit: contain;
	border: 1px solid rgba(0, 0, 0, 0.05);
}
.round-badge-time-edit {
    margin: auto 10px;
}
.top-player-row {
    display: flex;
    flex-flow: row;
}
.top-player-icon {
	width: 80px;
	height: 80px;
    margin: 10px auto;
    border-radius: 40px;
    background-color: #34BB87;
	border: 1px solid rgba(0, 0, 0, 0.05);
}
.top-player-username {
    color: black;
    font-weight: 700;
    margin-bottom: 20px;
}
.top-player-info-fill {
    flex: 1 0 auto;
    margin: 0px 10px;
    padding: auto 0px;
}
.top-player-main {
    color: #194381;
    font-weight: 900;
    font-size: 45px;
    margin-top: 20px;
}
.top-player-subInfo {
    color: #194381;
    font-size: 15px;
}
.group-team-badge {
    display: flex;
    flex-flow: row;
	margin: 0px 0px;
	height: 100%;
	box-shadow: 0 0 5px rgba(0,0,0,0.15);
	border: 1px solid rgb(87, 87, 87,0.15);
	border-radius: 5px;
	overflow: hidden;
}

.group-team-icon {
    flex: 0 0 auto;
	width: 45px;
    height: 45px;
    margin: 5px 10px;
}

.group-team-title {
    flex: 1 1 auto;
	font-size: 18px;
    margin: auto 5px;
}

.group-title {
	font-size: 14px;
    text-align: center;
    margin: 5px 10px;
}

.grouping-title {
	font-size: 18px;
    text-align: left;
    margin: 10px 20px;
}

.season-round-title {
	font-size: 18px;
    text-align: left;
    margin: 10px 20px;
}

.season-round-subtitle {
	font-size: 14px;
    text-align: left;
}

.knockout-game-team-icon {
    width: 30px;
    height: 30px;
    margin: 0px;
    border: 1px solid rgba(0,0,0, 0.2);
}

.knockout-game-team-title {
    font-size: 12px;
    font-weight: 800;
    margin-top: 5px;
    color: black;
}

.knockout-game-badge-outline {
    display: inline-block;
    margin: 5px;
    padding: 10px 20px;
    width: 420px;
    box-shadow: 2px 2px 2px rgba(0,0,0, 0.2);
    border: 1px solid rgba(0,0,0, 0.2);
}

.knockout-game-badge {
    display: inline-block;
    margin: 5px;
    padding: 10px 20px;
    width: 420px;
    box-shadow: 2px 2px 2px rgba(0,0,0, 0.2);
}

.knockout-game-info {
    font-size: 12px;
}

.knockout-game-info p {
    font-size: 12px;
    margin: 0;
}

.knockout-game-info b {
    font-size: 12px;
    margin: 0;
}

.organization-landing-container {
	height: 200px;
}

.organization-landing-wrapper {
	margin: -50px 10px 0px;
}

.organization-landing-header-title {
    margin-top: 50px;
    margin-bottom: 10px;
    font-weight: 700;
	font-size: 50px !important;
	line-height: 60px;
    color: black;
}

.organization-landing-header-sub-title {
	font-size: 36px !important;
	line-height: 36px;
	margin-bottom: 20px;
	margin-bottom: 20px;
	text-transform: uppercase;
	color: black;
}

.organization-landing-header-description {
	font-size: 15px !important;
	line-height: 26px !important;
	margin-bottom: 25px !important;
    font-weight: 200;
	color: black;
}

.organization-logo {
    display: inline;
    bottom: 0;
    width: 180px;
    height: 180px;
    border-radius: 90px;
    margin-right: 30px;
    background-color: white;
}

.date-header {
    margin: 5px 5px 5px 15px;
    width: 75px;
    height: 75px;
    border-radius: 10px;
    background-color: #F6F7FB;
}

.date-month {
    font-size: 32px;
    font-weight: 1000;
    text-align: center;
    color: #788492;
}

.date-day {
    font-size: 16px;
    font-weight: 600;
    margin-top: -5px;
    text-align: center;
    color: #788492;
}

.game-badge-edit-line {
    padding: 0px 2px;
    margin: 5px 0px;
}

.search-label {
    margin-right: 10px;
}

.organizer-header {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 48px;
    margin: 20px 0px;
    padding: 0px 20px;
}

.organizer-header-icon {
    width: 48px;
    height: 48px;
}

.organizer-header-title {
    flex: 1 1 auto;
    font-size: 24px;
    font-weight: 400;
    color: black;
    margin: auto 16px;
}

.organizer-header-user {
    flex: 0 0 auto;
    width: 48px;
    height: 48px;
    margin: auto 8px;
}

.organizer-header-settings {
    flex: 0 0 auto;
    font-size: 36px;
    font-weight: 400;
    color: rgb(59, 59, 59);
    line-height: 36px;
    margin: auto 8px;
}

.season-create-step-header {
    display: flex;
    flex-flow: row;
    margin: 20px 16px;
}

.season-create-step-num {
    flex: 0 0 auto;
    width: 32px;
    height: 32px;
    line-height: 32px;
    border-radius: 16px;
    text-align: center;
	color: white;
	background-color: #3063FF;
    margin-right: 15px;
}

.season-create-step-title {
    line-height: 32px;
    font-size: 18px;
}

.season-setup-step-header {
    display: flex;
    flex-flow: row;
    margin: 20px 16px;
}

.season-setup-step-num {
    flex: 0 0 auto;
    width: 28px;
    height: 28px;
    line-height: 28px;
    border-radius: 14px;
    text-align: center;
	color: white;
	background-color: #3063FF;
    margin-right: 15px;
}

.season-setup-step-title {
    line-height: 28px;
    font-size: 16px;
}

.overlay-limited-height {
    max-height: 420px;
    overflow-y: auto;
    margin: 30px 0px;
}

.season-ongoing-header {
    display: flex;
    flex-flow: row;
    margin: 30px 20px;
    width: auto;
}

.season-ongoing-desc {
    flex: 1 1 auto;
    font-size: 18px;
    margin: auto 0px;
}

@media (max-width: 1024px) {
    
    .organization-logo {
        width: 150px;
        height: 150px;
        border-radius: 75px;
    }

}
	
@media (max-width: 768px) {
    
    .organization-landing-container {
        height: 160px;
    }

    .organization-landing-wrapper {
        margin-top: -40px;
    }

    .organization-logo {
        width: 120px;
        height: 120px;
        margin-right: 10px;
    }
    
    .organization-landing-header-title {
        margin-top: 35px;
        margin-bottom: 0px;
        font-weight: 800;
        font-size: 35px !important;
    }

    .organization-landing-header-sub-title {
        font-size: 24px !important;
    }

    .organization-landing-header-description {
        font-size: 15px !important;
        margin-bottom: 15px !important;
    }

}
	
@media (max-width: 425px) {
    .date-header {
        width: 55px;
        height: 55px;
    }
    
    .date-month {
        font-size: 24px;
    }
    
    .date-day {
        font-size: 12px;
    }
    
    .champions-divider {
        padding-top: 20px;
    }    
    
    .organization-landing-container {
        height: 150px;
    }

	.organization-landing-container {
		padding-top: 40px;
	}

	.organization-landing-wrapper {
		padding-top: 20px;
        padding-left: 0px;
	}

	.organization-landing-header-title {
        margin-top: 20px;
		font-size: 22px !important;
		line-height: 30px;
        font-weight: 800;
		margin-bottom: 15px !important;
	}

	.organization-landing-header-sub-title {
		font-size: 24px !important;
		line-height: 24px;
		margin-bottom: 15px !important;
	}

	.organization-landing-header-description {
		font-size: 15px !important;
		line-height: 20px !important;
		margin-bottom: 25px !important;
	}

    .organization-logo {
        width: 80px;
        height: 80px;
        margin-left: 5px;
        margin-right: 5px;
    }
    
}
