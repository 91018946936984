.samba-landing {
    background-color: #EFF0E8;
}

.samba-header {
    position: relative;
    z-index: 9;
}

.samba-header-actions {
    background-color: #06620F;
    color: white;
}

.samba-fixed-header {
    position: fixed;
    z-index: 15;
    width: 100%;
}

.samba-fixed-header-placeholder {
    width: 100%;
    height: 80px;
}

.samba-header-filter-text {
    flex: 0 0 auto;
    color: white;
    font-size: 16px;
    margin: auto 20px;
    font-weight: 700;
}

.samba-header-filter-input {
    min-width: 120px;
    margin: 5px 10px;
    padding: 2px 10px;
    border: 1px solid white;
    border-radius: 4px;
    font-weight: 600;
}

.samba-header-filter-input select {
    color: white;
    background-color: black;
}

.samba-header-teams {
    display: flex;
    flex-direction: row;
    background-color: #EFF0E8;
    height: 40px;
}

.samba-header-teams-title {
    flex: 0 0 auto;
    color: #444;
    font-size: 16px;
    margin: auto 20px;
    font-weight: 700;
}

.samba-header-team-layout {
    flex: 1 1 auto;
    overflow-x: auto;
    display: flex;
    flex-direction: row;
}

.samba-header-team-item {
    width: 60px;
    height: 40px;
    padding: 5px 15px;
}

.samba-seasons-list {
    text-align: left;
    max-height: 40vh;
    overflow-y: auto;
}

.samba-season-item {
    padding: 5px 10px;
    font-size: 14px;
    margin: 2px 5px;
    border-radius: 4px;
    color: #444;
	border: 1px solid rgb(87, 87, 87,0.15);
	box-shadow: 0 0 5px rgba(0,0,0,0.15);
}

.samba-season-item-selected {
    padding: 2px 10px;
    margin: 2px 5px;
    border-radius: 4px;
    color: white;
    background-color: #A6DC34;
	border: 1px solid rgb(87, 87, 87,0.15);
	box-shadow: 0 0 5px rgba(0,0,0,0.15);
}

.samba-post-item {
    margin: 10px 5px;
	box-shadow: 0 0 5px rgba(0,0,0,0.15);
	border: 1px solid rgb(87, 87, 87,0.15);
	border-radius: 5px;
	overflow: hidden;
}

.samba-post-media-wrapper {
    width: 100%;
    height: 230px;
    position: relative;
    overflow: hidden;
}

.samba-post-media-normal-wrapper {
    width: 100%;
    height: 150px;
    position: relative;
    overflow: hidden;
}

.samba-post-media-wrapper:hover {
    transform: scale(110%);
}

.samba-post-media-child {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.samba-post-thumb-overlay {
    position: absolute;
    display: inline-flex;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0,0.55);
    text-align: center;
    overflow: hidden;
}

.samba-post-thumb-overlay-icon {
    position: relative;
    width: 64px;
    height: 64px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.samba-post-thumb {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.samba-post-contents-normal-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 10px;
    width: 100%;
    height: 240px;
    text-align: left;
}

.samba-post-contents-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 10px;
    width: 100%;
    height: 270px;
    text-align: left;
}

.samba-post-game-info {
    text-align: end;
    display: flex;
    flex-direction: row;
}

.samba-post-game-info-icon {
    width: 24px;
    height: 24px;
    margin: 0px 10px;
}

.samba-post-game-info-label {
    color: black;
    margin-bottom: 0;
}

.samba-post-item-title {
    font-size: 20px;
}

.samba-post-item-info-html {
    flex: 1 1 auto;
    overflow: hidden;
    white-space: nowrap;
}

.samba-line-post-item {
    display: flex;
    flex-direction: row;
    margin: 10px 5px;
	box-shadow: 0 0 5px rgba(0,0,0,0.15);
	border: 1px solid rgb(87, 87, 87,0.15);
	border-radius: 5px;
	overflow: hidden;
}

.samba-line-post-media-wrapper {
    flex: 0 0 auto;
    width: 160px;
    height: 160px;
    position: relative;
}

.samba-line-post-contents-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 10px;
    width: 100%;
    height: 160px;
    text-align: left;
}

.samba-badge-wrapper {
    display: flex;
    flex-direction: row;
    margin: 10px 0px;
}

.samba-post-badge {
    display: inline;
    padding: 2px 10px;
    margin: auto 0px;
    background-color: #F86053;
    color: white;
    border-radius: 24px;
    font-size: 12px;
}

.samba-header-action {
    font-size: 24px;
    height: 36px;
    font-weight: 700;
    margin-right: 10px;
}

.samba-header-main {
    position: relative;
    height: 260px;
}

.samba-header-image {
    position: absolute;
    width: 100%;
    height: 260px;
    object-fit: cover;
}

.samba-header-logo-wrapper {
    position: relative;
    height: 260px;
    border-bottom: 48px inset rgba(254, 221, 10, 0.3);
}

.samba-header-logo {
    position: relative;
    width: 180px;
    height: 180px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.samba-nav {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 0;
}

.samba-landing-header {
	display: flex;
	flex-flow: row;
	width: 100%;
	height: 48px;
}

.samba-landing-links {
	flex: 0 0 auto;
	height: 48px;
	margin-right: 15px;
}

.samba-landing-li {
	margin: auto 0px;
	z-index: 2;
}

.samba-landing-li a {
	display: table-cell;
	vertical-align: middle;
	height: 48px;
	padding: 8px 15px;
	font-size: 16px;
	line-height: 16px;
	font-weight: 700;
}

.samba-landing-li button {
	display: table-cell;
	vertical-align: middle;
	height: 48px;
	padding: 8px 15px;
	font-size: 16px;
	line-height: 16px;
	font-weight: 700;
}

.samba-landing-li .dropdown-li a {
	width: 160px;
}

.samba-landing-li .auth-btn {
	background-color: #A6DC34;
	color: #ccc;
	padding: 0px 15px;
	margin: 8px 10px;
	height: 36px;
	border: white;
	border-radius: 8px;
}

.samba-landing-li .dropdown-menu {
	background-color: rgba(0,0,0,.5);
}

.samba-landing-li .org-a {
	color: #ccc;
    background: none;
    border: none;
    text-decoration: none;
}

.samba-landing-li .org-a-active {
	color: #fff;
    background: none;
    border: none;
	border-bottom: 5px solid #C1E729;
    text-decoration: none;
}

.samba-landing-li .sign-up {
	background-color: #F86053;
	color: #fff;
	border-radius: 16px;
	height: 24px;
}

.samba-landing-li .dropdown-menu {
	background-color: rgba(0,0,0,.5);
}

.samba-landing-li .dropdown-li .org-a {
	color: #aaa;
}

.samba-landing-li .dropdown-li .org-a-active {
	color: #fff;
	background-color: #C1E729;
	border: none;
}

.samba-footer {
    background-color: #06620F;
    height: 120px;
    text-align: center;
    font-size: 20px;
    padding-top: 50px;
}

.samba-row {
    margin-top: 20px;
    margin-left: 0;
    margin-right: 0;
}

.samba-col-l {
    padding-left: 0;
    padding-right: 10px;
    padding-top: 0;
}

.samba-col-r {
    padding-left: 10px;
    padding-right: 0;
    padding-top: 0;
}

.samba-nav-placeholder {
    position: absolute;
    top: 0;
	width: 100%;
	object-fit: cover;
	height: 400px;
    background-color: rgb(0, 0, 0,0.55);
}

.samba-nav-image {
	width: 100%;
	object-fit: cover;
	height: 400px;
}

.samba-cover {
	position: absolute;
	width: 100%;
	height: 100%;
	bottom: 0;
	left: 0;
	right: 0;
	margin-right: 0 !important;
	margin-left: 0 !important;
}

.samba-wrapper {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
	margin: 0px 10%;
	text-align: center;
}

.samba-header-title {
	font-size: 48px !important;
	line-height: 48px;
	color: white;
	text-transform: uppercase;
	margin-bottom: 16px;
	font-weight: 800;
}

.samba-header-sub-title {
	font-size: 24px !important;
	line-height: 24px;
	color: white;
	font-weight: 500;
	text-transform: none;
}

.samba-following-heaer {
    border-radius: 4px;
	background-color: rgba(0,0,0,.2);
}

.samba-header-section-title {
    text-align: left;
    margin-top: 20px;
    background-color: #06620F;
    color: white;
    font-size: 18px;
    font-weight: 800;
    padding: 10px 15px;
    text-transform: uppercase;
    border-radius: 4px;
}

.samba-following {
    display: flex;
    flex-direction: row;
    padding: 0 20px;
    overflow-x: auto;
}

.samba-following::-webkit-scrollbar {
    height: 3px;
}

.samba-following-icon {
    width: 48px;
    height: 48px;
    margin: 5px 10px;
}

.samba-game-row {
    display: flex;
    flex-direction: row;
    text-align: center;
    background-color: white;
    padding: 2px 10px;
}

.samba-game-badge {
    width: 130px;
    padding: 0 1px;
    font-size: 11px;
}

.samba-game-season {
    height: 46px;
    position: relative;
}

.samba-game-type {
    position: relative;
    height: 30px;
    background-color: #06620F;
    color: white;
}

.samba-game-center {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    text-align: center;
}

.samba-game-date {
    display: inline-block;
    width: 60px;
    margin: 0px 2px;
    color: black;
    border-bottom: 1px solid black;
}

.samba-game-time {
    display: inline-block;
    width: 64px;
    color: black;
    border-bottom: 1px solid black;
}

.samba-game-item {
    display: flex;
    flex-direction: row;
    position: relative;
}

.samba-game-icon {
    width: 36px;
    height: 36px;
}

.samba-game-team {
    color: black;
    position: absolute;
    top: 50%;
    left: 40px;
    transform: translateY(-50%);
    width: calc(100% - 60px);
    flex: 1 1 auto;
}

.samba-game-score {
    color: black;
    position: absolute;
    top: 50%;
    right: 0;
    width: 20px;
    transform: translateY(-50%);
    flex: 0 0 auto;
}

.samba-ranking-badge {
    position: relative;
    padding: 0 1px;
    font-size: 14px;
    border-bottom: 1px dashed #666;
    background-color: white;
}

.samba-ranking-icon {
    margin-left: 36px;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 36px;
    height: 36px;
}

.samba-ranking-team {
    font-weight: 800;
    position: absolute;
    top: 50%;
    left: 80px;
    right: 36px;
    transform: translateY(-50%);
    width: calc(100% - 80px);
    flex: 1 1 auto;
}

.samba-ranking-score {
    position: absolute;
    top: 50%;
    left: 0;
    width: 36px;
    transform: translateY(-50%);
    flex: 0 0 auto;
    font-weight: 800;
    text-align: center;
}

.samba-ranking-points {
    position: absolute;
    top: 50%;
    right: 0;
    width: 36px;
    transform: translateY(-50%);
    flex: 0 0 auto;
    font-weight: 800;
    font-size: 18px;
    text-align: center;
}

.samba-team-tile {
    position: relative;
    background: url(./img/pattern.svg) center top / cover no-repeat rgb(242, 30, 39);
    color: white;
    height: 280px;
    text-align: center;
    margin: 20px 2px;
    padding-top: 20px;
}

.samba-team-tile-title {
    font-size: 24px;
    color: white;
    font-weight: 900;
}

.samba-team-tile-score {
    position: absolute;
    font-size: 50px;
    color: white;
    font-weight: 900;
    margin-top: 20px;
    bottom: 10px;
    left: 0;
    right: 0;
}

.samba-team-tile-icon {
    position: absolute;
    width: 72px;
    height: 72px;
    bottom: 90px;
    left: 50%;
    transform: translateX(-50%);
    right: 0;
}

.samba-team-tile-pattern {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.samba-team-header {
    display: flex;
    flex-direction: row;
    background: url(./img/pattern.svg) right top / contain no-repeat rgb(242, 30, 39);
    color: white;
    padding: 30px 0px;
}

.samba-player-header {
    position: relative;
    background: url(./img/pattern.svg) right bottom / contain no-repeat rgb(242, 30, 39);
    color: white;
    padding: 0px;
    text-align: center;
}

.samba-statistics-wrapper {
    padding: 15px 20px;
}

.samba-statistics-title {
    font-size: 28px;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.samba-statistics-line {
    margin: 0px 0px 15px;
    background-color: rgb(244, 244, 244);
    border: 1px solid rgb(234, 234, 234);
    padding: 5px 0px;
}

.samba-statistics-cell {
    display: flex;
    flex-direction: row;
    color: black;
    font-size: 20px;
}

.samba-statistics-cell-icon {
    flex: 0 0 auto;
    margin: auto 0;
    padding: 0 10px;
}

.samba-statistics-cell-title {
    flex: 1 1 auto;
    font-weight: 700;
}

.samba-statistics-cell-number {
    flex: 0 0 auto;
    font-weight: 700;
    color: rgb(255, 75, 68);
}

.samba-statistics-cell.right-border {
    border-right: 1px solid rgb(234, 234, 234);
}

.samba-game-badge {
    width: calc(100% - 20px);
    margin: 5px 10px;
    border: 1px solid rgba(0,0,0,0.15);
}

.samba-game-badge-date {
    display: flex;
    flex-direction: row;
    padding: 10px 15px;
    color: #333;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 700;
    background-color: rgb(244, 244, 244);
    border: 1px solid rgb(234, 234, 234);
}

.samba-game-badge-main {
    display: flex;
    flex-direction: row;
    padding: 15px 5px;
}

.samba-game-badge-score {
    flex: 0 0 auto;
    width: 80px;
    font-size: 28px;
    font-weight: 900;
    text-align: center;
    color: black;
    margin: auto 0px;
}

.samba-game-badge-team {
    flex: 1 1;
    font-size: 24px;
    text-align: center;
    color: black;
    text-transform: uppercase;
    margin: auto 0px;
}

.samba-game-badge-icon {
    flex: 0 0 auto;
    width: 36px;
    height: 36px;
    margin: auto 0px;
}

.samba-game-badge-tag {
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    padding: 5px 10px;
    color: #7E49C3;
}

.samba-game-badge-class {
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    padding: 5px 10px;
    background-color: #F86053;
    color: white;
}

.samba-game-line-main {
    display: flex;
    flex-direction: row;
    padding: 15px 5px;
}

.samba-game-line-score {
    flex: 0 0 auto;
    width: 80px;
    font-size: 14px;
    font-weight: 900;
    text-align: center;
    color: black;
    margin: auto 0px;
}

.samba-game-line-team {
    flex: 1 1;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    color: black;
    text-transform: uppercase;
    margin: auto 0px;
}

.samba-game-line-icon {
    flex: 0 0 auto;
    width: 24px;
    height: 24px;
    margin: auto 0px;
}

.samba-game-line-header {
    min-height: 30px;
    margin: 0;
    background-color: rgb(244, 244, 244);
}

.samba-game-line {
    margin: 0;
}

.samba-game-line-cell {
    font-size: 12px;
    font-weight: 700;
    padding: 0;
    margin: auto 0px;
    text-align: center;
}

.samba-game-line-info {
    font-size: 12px;
    font-weight: 700;
    padding: 0;
    margin: auto 0px;
    text-align: center;
}

@media (max-width: 768px) {
	.samba-header-title {
		font-size: 36px !important;
		line-height: 36px;
		margin-bottom: 15px;
	}
	
	.samba-header-sub-title {
		font-size: 24px !important;
		line-height: 24px;
	}
}

@media (max-width: 425px) {
    
    .samba-header-logo-wrapper {
        border-bottom: 36px inset rgba(254, 221, 10, 0.3);
    }

    .samba-landing-header {
        height: 36px;
    }
    
    .samba-landing-links {
        height: 36px;
    }

    .samba-landing-li a {
        height: 36px;
        padding: 4px 8px;
        font-size: 12px;
        line-height: 12px;
    }

    .samba-landing-li button {
        height: 36px;
        padding: 4px 8px;
        font-size: 12px;
        line-height: 12px;
    }

    .samba-landing-li .dropdown-li a {
        width: 160px;
    }

    .samba-landing-li .auth-btn {
        padding: 0px 15px;
        margin: 4px 10px;
        height: 28px;
        border-radius: 6px;
    }

    .samba-landing-li .dropdown-menu {
        background-color: rgba(0,0,0,.5);
    }

    .samba-col-l {
        padding-left: 0;
        padding-right: 0;
        padding-top: 10px;
    }
    
    .samba-col-r {
        padding-left: 0;
        padding-right: 0;
        padding-top: 10px;
    }
    
	.samba-header-title {
		font-size: 32px !important;
		line-height: 32px;
		margin-bottom: 10px;
	}
	
	.samba-header-sub-title {
		font-size: 18px !important;
		line-height: 18px;
	}

	.samba-wrapper {
		text-align: center;
	}
	
	.samba-nav-placeholder {
		height: 400px;
	}
}
