.survey-title {
    font-size: 24px;
}

.survey-text {
    font-size: 18px;
}

.survey-option-icon {
    width: 12px;
    height: 12px;
}

.survey-option-item {
    display: inline-block;
    font-size: 15px;
    margin: auto 5px;
}

.survey-question-text {
    font-size: 16px;
    font-weight: 800;
    color: #555;
}

.survey-question-divider {
	height: 1px;
	background-color: rgba(0, 0, 0, 0.2);
    margin: 5px 0px;
}