.home-nav-title {
	display: inline-block;
	padding: 8px 5px;
	font-size: 20px;
	line-height: 24px;
	font-weight: 900;
	color: #2C353D;
}

.home-nav-title-inverse {
	display: inline-block;
	padding: 8px 5px;
	font-size: 20px;
	line-height: 24px;
	font-weight: 900;
	color: white;
}

.home-nav-title-str {
	display: inline-block;
	padding: 8px 15px;
	font-size: 15px;
	line-height: 16px;
	font-weight: 700;
	border-radius: 16px;
	color: #2C353D;
	text-transform: uppercase;
}

.home-nav-title-str-active {
	display: inline-block;
	padding: 10px 15px 5px;
	font-size: 15px;
	line-height: 40px;
	font-weight: 700;
	color: #2C353D;
	border-bottom: 5px solid #C1E729;
	text-transform: uppercase;
}

.home-nav-title-str-inverse {
	display: inline-block;
	padding: 8px 15px;
	font-size: 15px;
	line-height: 16px;
	font-weight: 700;
	border-radius: 16px;
	color: white;
	text-transform: uppercase;
}

.home-nav-title-str-inverse-active {
	display: inline-block;
	padding: 10px 15px 5px;
	font-size: 15px;
	line-height: 40px;
	font-weight: 700;
	color: white;
	border-bottom: 5px solid #C1E729;
	text-transform: uppercase;
}

.reg-icon-user {
	width: 36px;
	height: 36px;
	background-color: white;
	border: 1px solid #444;
	border-radius: 18px;
	margin: 0px 8px 10px 0px;
}

.reg-label-user {
	font-size: 20px;
	background-color: white;
	border-radius: 12px;
	margin: 5px 8px 5px 0px;
}

.reg-price-name {
	margin: 5px 10px 0px 0px;
	font-size: 17px;
}

.reg-price-label {
	color: #EEB825;
	font-size: 26px;
}

.reg-info-label {
	font-size: 14px;
}

.reg-method-info-label {
	font-size: 12px;
}

.reg-icon-team {
	width: 15px;
	height: 15px;
	margin-right: 5px;
}

.reg-info {
	font-size: 12px;
}

.home-cover {
	position: absolute;
	width: 100%;
	height: 100%;
	bottom: 0;
	left: 0;
	right: 0;
	margin-right: 0 !important;
	margin-left: 0 !important;
	background-color: rgba(0,0,0,.5);
}

.home-logo {
	width: 40px;
    height: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 10px;
}

.home-wrapper {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
	margin: 0px 10%;
	text-align: center;
}

.home-nav-placeholder {
	width: 100%;
	height: 100%;
	object-fit: cover;
	height: 600px;
	background-color: #A6DC34;
}

.bg-info {
    background-color: white!important;
}
.progress-bar-animated {
    animation: progress-bar-stripes 1s linear infinite;
}

.progress-bar-striped {
    background-image: linear-gradient(45deg,rgba(33,33,33,.15) 25%,transparent 25%,transparent 50%,rgba(33,33,33,.15) 50%,rgba(33,33,33,.15) 75%,transparent 75%,transparent);
    background-size: 1rem 1rem;
}

.home-nav-placeholder-title {
	font-size: 60px !important;
	line-height: 60px;
	color: white;
	text-transform: uppercase;
	margin-top: 120px;
	font-weight: 800;
}

.home-nav-slide-img {
	width: 100%;
	object-fit: cover;
	height: 600px;
	/* background-color: #A6DC34; */
	background-color: #000;
}

.home-header-icon {
	flex: 0 0 auto;
	width: 120px;
	height: 120px;
	margin: 0px 40px;
}

.home-header-title-small {
	font-size: 48px !important;
	line-height: 48px;
	color: white;
	margin-bottom: 20px;
	font-weight: 800;
}

.home-header-title {
	font-size: 60px !important;
	line-height: 60px;
	color: white;
	text-transform: uppercase;
	margin-bottom: 20px;
	font-weight: 800;
}

.home-header-sub-title {
	font-size: 24px !important;
	line-height: 24px;
	color: white;
	font-weight: 500;
	text-transform: none;
}

.home-header-description {
	font-size: 15px !important;
	line-height: 26px !important;
	color: white;
}

img {
	object-fit: contain;
}

.org-game-team-icon {
    width: 60px;
    height: 60px;
    margin: 0px 10px;
}

.org-game-team-title {
	color: #777;
	font-size: 10px;
}

.org-game-team-title-highlight {
	color: #eee;
	font-size: 10px;
}

.sanction-game-team-icon {
    width: 16px;
    height: 16px;
    margin: 0px 2px;
}

.sanction-game-team-title {
	font-size: 10px;
}

.home-nav-inverse-wrapper {
	position: fixed;
	z-index: 10000;
	width: 100%;
	background-color: rgba(0,0,0,.5);;
}

.btn-home-main {
	font-family: 'Raleway', sans-serif;
	text-transform: uppercase;
	color: #000;
	background-color: #C1E729;
	padding: 10px 30px;
	letter-spacing: 1px;
	margin: 10px 0;
	font-size: 12px;
	font-weight: 750;
	border-radius: 0px;
	transition: all 0.5s linear;
	border: 2px solid white;
}
.btn-home-main:hover, .btn-custom:focus, .btn-custom.focus, .btn-custom:active, .btn-custom.active {
	color: #000;
	background-image: none;
	background-color: #A6DC34;
}

.btn-team-main {
	font-family: 'Raleway', sans-serif;
	text-transform: uppercase;
	color: #000;
	background-color: #C1E729;
	padding: 5px 30px;
	letter-spacing: 1px;
	margin: 5px 10px;
	font-size: 12px;
	font-weight: 750;
	border-radius: 0px;
	transition: all 0.5s linear;
	border: 2px solid white;
}
.btn-team-main:hover, .btn-custom:focus, .btn-custom.focus, .btn-custom:active, .btn-custom.active {
	color: #000;
	background-image: none;
	background-color: #A6DC34;
}

.btn-home-sec {
	font-family: 'Raleway', sans-serif;
	text-transform: uppercase;
	color: white;
	background-color: transparent;
	padding: 10px 30px;
	letter-spacing: 1px;
	margin: 10px 0;
	font-size: 12px;
	font-weight: 750;
	border-radius: 0px;
	transition: all 0.5s linear;
	border: 2px solid white;
}
.btn-sec-main:hover, .btn-custom:focus, .btn-custom.focus, .btn-custom:active, .btn-custom.active {
	color: #eee;
	background-image: none;
}

.home-service-wrapper {
	position: relative;
	width: 100%;
	height: 360px;
}

.home-service-wrapper-inverse {
	position: relative;
	width: 100%;
	height: 360px;
}

.home-service-overlay {
	position: absolute;
	padding: 50px 40px;
	width: 100%;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10;
	text-align: left;
	background-color: rgba(0,53,38,.8);
}

.home-service-image {
	width: 100%;
	height: 360px;
	object-fit: cover;
}

.home-service-title {
	color: white;
	font-size: 25px;
	margin-bottom: 25px;
	text-transform: none;
}

.home-service-texts {
	color: white;
	font-size: 16px;
}

.home-service-overlay-inverse {
	position: absolute;
	padding: 50px 40px;
	width: 100%;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10;
	text-align: left;
	background-color: rgba(255,255,255,.8);
}

.home-service-title-inverse {
	color: #222;
	font-size: 25px;
	margin-bottom: 25px;
	text-transform: none;
}

.home-service-texts-inverse {
	color: #222;
	font-size: 16px;
}

.home-video-wrapper {
	position: relative;
	width: 100%;
	height: 440px;
}

.home-video-overlay {
	position: absolute;
	padding: 50px 30px;
	margin: 0;
	width: 100%;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10;
	text-align: left;
	background-color: rgba(0,53,38,.8);
}

.home-video-image {
	width: 100%;
	height: 440px;
	object-fit: cover;
}

.home-video-player {
	width: 100%;
	max-width: 720px;
	margin: auto;
	height: 360px;
	object-fit: cover;
}

.home-edit-thumb {
	height: 360px;
	object-fit: contain;
}

.home-sponser-thumb {
	height: 60px;
	object-fit: contain;
}

.col-no-padding {
	padding: 0;
}

.row-no-margin {
	margin: 0;
}

.org-div-wrapper {
	padding: 0px;
}

.org-div-left {
	padding: 0px;
}

.org-div-right {
	padding: 0px;
}

.org-landing-contents-bg {
	background-color: white;
	padding-left: 30px;
	padding-right: 30px;
	padding-top: 20px;
	padding-bottom: 60px;
	margin-bottom: 60px;
}

.org-landing-contents-bg-blue {
	background-color: rgba(255,255,255,.8);
	padding-left: 30px;
	padding-right: 30px;
	padding-top: 20px;
	padding-bottom: 60px;
	margin-bottom: 60px;
}

.org-landing-contents-title {
    font-size: 48px;
    margin-bottom: 5px;
}

.org-landing-contents-subtitle {
    font-size: 24px;
    margin-bottom: 5px;
}

.contact-us-wrapper {
	margin: 0px -15px;
}

.org-landing-contents-info-title {
    font-size: 21px;
    margin-bottom: 10px;
}
.org-landing-contents-info-text {
    font-size: 14px;
    margin-bottom: 10px;
}


.org-landing-contents-text {
	color: #333;
	font-size: 18px;
}

.org-landing-header {
	display: flex;
	flex-flow: row;
	width: 100%;
	height: 64px;
	background-color: rgba(0,0,0,.8);
}

.org-landing-header-opaque {
	display: flex;
	flex-flow: row;
	width: 100%;
	height: 64px;
	background-color: white;
}

.org-landing-icon {
	flex: 1 1 auto;
	margin: 0px 10px;
	z-index: 1;
}

.org-landing-links {
	flex: 0 0 auto;
	height: 64px;
	margin-right: 15px;
}

.org-landing-li {
	margin: auto 0px;
	z-index: 2;
}

.org-landing-li a {
	display: table-cell;
	vertical-align: middle;
	height: 64px;
	padding: 8px 15px;
	font-size: 16px;
	line-height: 16px;
	font-weight: 700;
	text-decoration: none;
}

.org-landing-li button {
	display: table-cell;
	vertical-align: middle;
	height: 64px;
	padding: 8px 15px;
	font-size: 16px;
	line-height: 16px;
	font-weight: 700;
}

.dropdown .dropdown-toggle {
	background-color: transparent;
	border: none;
}

.org-landing-li .dropdown-li a {
	width: 160px;
}

.org-landing-li .auth-btn {
	background-color: #A6DC34;
	color:rgb(0, 0, 0,0.55);
	padding: 0px 15px;
	margin: 8px 10px;
	height: 36px;
	border: white;
	border-radius: 8px;
}

.org-landing-li .dropdown-menu {
	background-color: rgba(0,0,0,.5);
}

.org-landing-li button {
	display: table-cell;
	vertical-align: middle;
	color: #ccc;
	padding: 8px 15px;
	font-size: 16px;
	line-height: 16px;
	font-weight: 700;
}

.org-landing-li .org-a {
	color: #ccc;
}

.org-landing-li .org-a-active {
	color: #fff;
	border-bottom: 5px solid #C1E729;
}

.org-landing-li .sign-up {
	background-color: #F86053;
	color: #fff;
	border-radius: 16px;
	height: 24px;
}

.org-landing-li .dropdown-menu {
	background-color: rgba(0,0,0,.5);
}

.org-landing-header-opaque .org-landing-li button {
	color: #333;
}

.org-landing-header-opaque .org-landing-li .org-a {
	color: #333;
}

.org-landing-header-opaque .org-landing-li .org-a-active {
	color: #000;
	border-bottom: 5px solid #C1E729;
}

.org-landing-li .dropdown-li .org-a {
	color: #aaa;
}

.org-landing-li .dropdown-li .org-a-active {
	color: #fff;
	background-color: #C1E729;
	border: none;
}

.org-landing-menu-toggle {
	font-size: 32px;
	line-height: 64px;
	font-weight: 700;
	margin-left: auto;
	margin-right: 10px;
	color: #fff;
}

.org-landing-dropdown-menu {
	position: relative;
	z-index: 10;
	background-color: rgba(0,0,0,.8);
}

.org-landing-dropdown-menu a {
	display: block;
	padding: 8px 15px;
	font-size: 16px;
	line-height: 16px;
	font-weight: 700;
	width: 100%;
}

.org-landing-dropdown-menu button {
	display: block;
	color: #ccc;
	padding: 8px 15px;
	font-size: 16px;
	line-height: 16px;
	font-weight: 700;
	width: 100%;
	text-align: left;
}

.org-landing-dropdown-menu .dropdown-li .org-a {
	color: #aaa;
}

.org-landing-dropdown-menu .dropdown-li .org-a-active {
	color: #fff;
	background-color: #C1E729;
	border: none;
}

.org-player-card-bg-photo {
	position: absolute;
	background-color: #1A4480;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.org-player-card-bg-overlay {
	position: absolute;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 0px 20px;
	background-color: rgba(0,0,0,0.45);
}

.org-player-card-photo-overlay {
	z-index: 9999;
}

.fund-player-card-photo {
	width: 96px;
	height: 96px;
	margin-top: 24px;
	margin-bottom: 24px;
}

.org-player-card-photo {
	width: 256px;
	height: 256px;
	margin-top: 48px;
	margin-bottom: 24px;
}

.org-player-card-info {
	margin-top: 48px;
	margin-bottom: 24px;
}

.statistics-badge {
	background-color: #1A4480;
	margin-top: 10px;
	margin-left: 10px;
	margin-right: 10px;
	margin-bottom: 10px;
	box-shadow: 0 0 5px rgba(0,0,0,0.15);
	border: 1px solid rgb(87, 87, 87,0.15);
	border-radius: 5px;
	overflow: hidden;
}

.statistics-badge-number {
	color: white;
	margin-top: 12px;
	font-size: 24px;
	text-align: center;
}

.statistics-badge-title {
	color: white;
	font-size: 10px;
	text-align: center;
}

.statistics-badge-info {
	color: white;
	font-size: 14px;
	text-align: left;
	margin: 10px 5px;
}

.org-register-layout {
	display: inline-block;
	position: fixed;
	top: 72px;
	right: 15px;
	width: 280px;
	background-color: white;
	border-radius: 8px;
	box-shadow: 0 0 5px rgba(0,0,0,0.15);
	border: 1px solid rgb(87, 87, 87,0.15);
	z-index: 3000;
}

.org-register-title {
	font-size: 18px;
	color: black;
	margin: 25px 5px;
}

.org-contact-wrapper {
	background-color: rgba(255,255,255,.4);
	border: 1px solid rgba(0,0,0,0.45);
	box-shadow: 0 0 5px rgba(0,0,0,0.15);
	border-radius: 10px;
	padding: 20px 30px;
	margin: 20px 0px;
}

.org-contact-item {
	display: flex;
	flex-direction: row;
	margin: 10px 15px;
}

.org-contact-item-title {
	width: 150px;
	font-size: 21px;
	font-weight: 700;	
	color: black;
	margin: 5px 0px;
}

.org-contact-item-text {
	font-size: 21px;
	color: #555;
	margin: 5px 0px;
	background-color: rgba(0,0,0,0.15);
	border-radius: 16px;
	padding: 0px 10px;
}

.account-table {
	margin-top: 20px;
}

.team-auth {
	background-color: rgba(0,0,0,.7);
	position: fixed;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	z-index: 999999999999999;
}

.team-auth-wrapper {
	background-color: white;
	position: relative;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 480px;
	max-height: 90%;
	overflow-y: auto;
	text-align: center;
	border-radius: 8px;
	padding: 20px 30px;
}

.team-auth-msg {
	color: #333;
	font-size: 14px;
}

.auth-wrapper {
	padding: 25px 20px;
}

.to-do-title {
	color: black;
	font-size: 16px;
	margin: 4px 8px;
	font-weight: 700;
}

.to-do-item {
	color: black;
	font-size: 12px;
	margin: 4px 8px;
}

.to-do-num {
	color: #F86053;
	margin-left: 10px;
	margin-right: 10px;
	font-weight: 700;
}

.card-contact-item {
	display: flex;
	flex-direction: row;
	margin: 5px 20px;
}

.card-contact-title-l {
	font-size: 18px;
	color: #444;
	margin: 20px 0px 10px;
}

.card-contact-title {
	width: 120px;
	font-size: 14px;
	color: #444;
}

.card-contact-text {
	font-size: 14px;
	color: black;
	font-weight: 600;
}

.bingo-num-edit {
	display: inline-block;
	width: 80px;
	margin-right: 5px;
}

.fund-landing {
	display: flex;
	flex-flow: row;
	text-align: center;
	min-height: 150px;
}

.fund-landing-thumb {
	padding: 0px 10px;
	width: 25%;
	height: 200px;
	object-fit: cover;
}

.fund-landing-thumb-full {
	width: 100%;
	max-height: 600px;
	object-fit: contain;
	background-color: white;
	border: solid 1px rgba(0,0,0,.4);
}

.fund-landing-info {
	padding: 0px 10px;
	text-align: left;
	min-height: 150px;
}

.fund-table-title {
	font-size: 20px;
	color: black;
}

.fund-table-result {
	display: inline-block;
	font-size: 20px;
	color: #F5A623;
	margin-bottom: 0px;
}

.fund-result-icon {
	width: 24px;
	height: 24px;
	margin-right: 10px;
	margin-top: -4px;
}

.fund-table-info {
	font-size: 12px;
	color: #555;
}

.fund-landing-title {
	color: white;
	font-size: 36px;
	font-weight: 700;
}

.fund-landing-sub-title {
	color: white;
	font-size: 21px;
	font-weight: 500;
}

.fund-landing-text {
	color: #555;
	font-size: 14px;
	font-weight: 500;
}

.fund-landing-overlay {
    text-align: left;
    background-color: rgba(0,0,0,.4);
}

.bingo-overlay {
	background-color: rgba(0,0,0,.7);
	position: fixed;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	z-index: 999999999999999;
}

.bingo-overlay-wrapper {
	background-color: white;
	position: relative;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 640px;
	max-height: 80%;
	overflow-y: auto;
	text-align: center;
	border-radius: 8px;
	padding: 20px 30px;
}

.bingo-num {
	width: 36px;
	margin-right: 0;
	padding-left: 5px;
	padding-right: 5px;
}

.mail-box-detail {
	margin: 20px 10px;
	padding: 20px 30px;
	border-radius: 6px;
	background-color: white;
	border: 1px solid rgba(0,0,0,0.25);
	box-shadow: 0 0 5px rgba(0,0,0,0.15);
}

.mail-menu {
	width: 360px;
	padding: 20px 0px;
	margin-right: 15px;
}

.mail-menu-item {
	background-color: white;
	border-radius: 6px;
	font-size: 18px;
	padding: 9px 15px;
	color: #444;
	margin: 3px 0px;
	border: 1px solid rgba(0,0,0,0.25);
	box-shadow: 0 0 5px rgba(0,0,0,0.15);
}

.mail-menu-item-selected {
	background-color: white;
	border-radius: 6px;
	font-size: 18px;
	padding: 9px 15px;
	color: #F86053;
	margin: 3px 0px;
	border: 1px solid #F86053;
	box-shadow: 0 0 5px rgba(0,0,0,0.15);
}

.register-overlay-contents {
    position: relative;
    height: calc(100% - 120px);
    overflow-y: auto;
    margin-bottom: 10px;
}

.register-overlay-contents-middle {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    text-align: center;
    transform: translateY(-50%);
}

.register-overlay-text {
    padding: 0px 48px;
    font-size: 15px;
}

.register-overlay-icon {
    font-size: 96px;
}

@media (max-width: 1024px) {
	
	.mail-menu {
		width: 300px;
	}
}
	
@media (max-width: 768px) {
	.mail-menu {
		width: 240px;
	}

	.fund-table-result {
		font-size: 16px;
	}
	
	.bingo-overlay-wrapper {
		width: 420px;
	}
	
	.bingo-num {
		width: 24px;
		margin-right: 0;
	}	
	
	.org-landing-contents-bg-blue {
		padding-left: 10px;
		padding-right: 10px;
	}

	.home-header-icon {
		width: 96px;
		height: 96px;
		margin: 0px 30px;
	}

	.home-header-title-small {
		font-size: 36px !important;
		line-height: 36px;
		margin-bottom: 15px;
	}
	
	.home-header-title {
		font-size: 48px !important;
		line-height: 48px;
		margin-bottom: 15px;
	}
	
	.home-header-sub-title {
		font-size: 24px !important;
		line-height: 24px;
	}

	.home-header-description {
		font-size: 15px !important;
		line-height: 15px !important;
	}

	.team-auth-wrapper {
		width: 420px;
		padding: 20px 10px;
	}
	
}
	
@media (max-width: 425px) {
    .register-overlay-text {
        padding: 0px 5px;
    }

	.mail-box-detail {
		margin: 20px 5px;
		padding: 20px 5px;
	}
	
	.mail-menu-item {
		font-size: 9px;
		padding: 5px 3px;
	}
	
	.mail-menu-item-selected {
		font-size: 9px;
		padding: 5px 3px;
	}
	
	.mail-menu {
		width: 80px;
	}

	.team-auth-wrapper {
		width: 90%;
		padding: 10px 5px;
	}
	
	.fund-table-result {
		font-size: 14px;
	}
	
	.bingo-overlay-wrapper {
		width: 360px;
	}
	
	.bingo-num {
		width: 20px;
		margin-right: 0;
	}	

	.fund-landing {
		display: block;
	}

	.fund-landing-thumb {
		margin-right: 10px;
		width: 100%;
		height: 150px;
	}
	
	.org-player-card-photo {
		margin-top: 48px;
		margin-bottom: 24px;
	}
	
	.org-player-card-info {
		margin-top: 0px;
		margin-bottom: 24px;
	}
	
	.contact-us-wrapper {
		margin: 0px 10px;
	}

	.home-nav-placeholder {
		height: 400px;
	}
	
	.home-nav-placeholder-title {
		font-size: 36px !important;
		line-height: 36px;
		margin-top: 110px;
	}
	
	.home-nav-slide-img {
		height: 400px;
	}
	
	.org-landing-contents-bg-blue {
		padding-left: 0px;
		padding-right: 0px;
		padding-top: 10px;
		padding-bottom: 20px;
		margin-bottom: 20px;
	}

	.home-header-icon {
		width: 80px;
		height: 80px;
		margin: 0px 30px;
	}

	.home-header-title-small {
		font-size: 32px !important;
		line-height: 32px;
		margin-bottom: 10px;
	}
	
	.home-header-title {
		font-size: 36px !important;
		line-height: 36px;
		margin-bottom: 10px;
	}
	
	.home-header-sub-title {
		font-size: 18px !important;
		line-height: 18px;
	}

	.home-header-description {
		font-size: 15px !important;
		line-height: 26px !important;
		color: white;
	}

	.home-wrapper {
		text-align: center;
	}
	
	.home-nav-title {
		display: none;
	}

	.home-nav-title-inverse {
		display: none;
	}

	.org-contact-wrapper {
		padding: 20px 5px;
	}
	
	.org-contact-item {
		margin: 10px 5px;
	}
	
	.org-contact-item-title {
		width: 120px;
		font-size: 18px;
	}
	
	.org-contact-item-text {
		font-size: 18px;
	}
	
}