.org-membership-bg {
    background-image: url(./img/ic_membership_badge.png);
    background-position: right;
    background-size: contain;
    background-repeat: no-repeat;
}

.org-membership-tile {
	text-align: left;
	padding: 25px 40px;
	margin-top: 10px;
	margin-left: 10px;
	margin-right: 10px;
	margin-bottom: 10px;
	height: 100%;
	box-shadow: 0 0 5px rgba(0,0,0,0.15);
	border: 1px solid rgb(87, 87, 87,0.15);
	border-radius: 5px;
	overflow: hidden;
	background-color: rgba(255,255,255,0.8);
}

.org-membership-tile:hover {
	margin-top: 5px;
	margin-bottom: 15px;
	box-shadow: 0 0 10px rgba(0,0,0,0.15)
}

.org-membership-title {
	font-size: 21px;
	color: black;
	font-weight: 400;
}

.org-membership-desc {
	margin-top: 15px;
	font-size: 14px;
}

.org-membership-price-layout {
	margin-top: 30px;
	margin-bottom: 20px;
}

.org-membership-price {
	font-size: 32px;
	line-height: 32px;
	font-weight: 800;
	color: black;
	margin-bottom: 20px;
}

.org-membership-price-info {
	font-size: 14px;
	line-height: 16px;
}

.btn-org-membership {
	background-color: #012BD7;
	border-color: #DDDDDD;
	width: 100%;
	padding: 10px 12px;
	font-size: 18px;
	font-weight: 600;
	border-radius: 8px;
}

.btn-org-membership:hover {
	background-color: #8fb4e9;
	border-color: #DDDDDD;
}

.btn-org-membership:active {
	background-color: #3684bb;
	border-color: #DDDDDD;
}

.btn-org-membership:focus {
	background-color: #3684bb;
	border-color: #DDDDDD;
}

.membership-title {
	color: black;
	font-size: 22px;
	font-weight: bold;
	text-shadow: 2px 2px rgba(0, 0, 0, 0.2);
	margin-top: 10px;
}

.membership-price {
	color: black;
	font-size: 22px;
	font-weight: bold;
	text-shadow: 2px 2px rgba(0, 0, 0, 0.2);
}

.btn-membership {
	background-color: #63bcfd;
	border-radius: 18px;
	border: none;
	box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
	text-transform: uppercase;
	font-weight: 600;
	font-size: 16px;
	margin: 5px 0;
	padding-left: 25px;
	padding-right: 25px;
}

.membership-tile {
	text-align: left;
	padding: 25px 30px;
	margin-top: 10px;
	margin-left: 10px;
	margin-right: 10px;
	margin-bottom: 10px;
	height: 100%;
	box-shadow: 0 0 5px rgba(0,0,0,0.15);
	border: 1px solid rgb(87, 87, 87,0.15);
	border-radius: 5px;
	overflow: hidden;
	background-color: rgba(255,255,255,0.8);
}

.membership-tile:hover {
	margin-top: 5px;
	margin-bottom: 15px;
	box-shadow: 0 0 10px rgba(0,0,0,0.15)
}

.membership-desc {
	margin-top: 15px;
	font-size: 14px;
}

.membership-status {
	font-size: 16px;
	font-weight: 600;
	text-align: center;
}

.membership-benefits {
	font-size: 16px;
	margin: 10px 15px 30px;
}

@media (max-width: 425px) {
		
	.org-membership-tile {
		padding: 15px 20px;
	}
}