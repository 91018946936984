
.dashboard-title {
	color:skyblue;
	margin-top: 20px;
	margin-bottom: 15px;
	margin-left: 25px;
	margin-right: 25px;
	font-size: 1.5rem;
	font-weight: 200;
}

.course-container {
    display: flex;
    flex-wrap: wrap;
    padding: 0 2px;
	margin: 0 16px 16px 16px;
	background-color: transparent;
}

.course-item {
    padding: 0px;
    width: 33.333%;
    word-wrap: break-word;
	box-sizing: border-box;
	
}

.course-progress {
	margin-top: 10px;
	margin-left: 10px;
	margin-right: 10px;
	margin-bottom: 10px;
}

.course-actions {
    display: flex;
    flex-wrap: wrap;
	margin: 0px;
	width: 100%;
	font-size: 0px;
}

.course-title {
	padding-top: 20px;
	padding-left: 10px;
	padding-right: 10px;
	padding-bottom: 20px;
	font-size: 1.125rem;
	text-align: center;
}
