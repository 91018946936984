
.blue-landing {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url(./img/blue_landing_bg.jpg);
	background-color: black;
    width: 100vw;
    height: 100vh;
}

.blue-nav-wrapper {
	position: fixed;
	z-index: 10000;
	width: 100%;
    height: 60px;
}

.blue-nav-placeholder {
	width: 100%;
	height: 100%;
	object-fit: cover;
	height: 600px;
	background-color: transparent;
}

.blue-contents-wrapper {
    position: fixed;
    width: 100%;
    top: 60px;
    height: calc(100vh - 60px);
    overflow-y: scroll;
}

.landing-dashboard {
    position: fixed;
    width: 100%;
    top: 60px;
    height: calc(100% - 60px);
	display: flex;
	flex-flow: column;
}

.landing-dashboard-header {
	flex: 0 0 auto;
	display: flex;
	flex-direction: row;
	width: 100%;
}

.landing-dashboard-contents {
	flex: 1 1 auto;
	background-color: rgba(255,255,255,.8);
	padding-left: 30px;
	padding-right: 30px;
	padding-top: 20px;
	padding-bottom: 20px;
}

.landing-dashboard-contents-wrapper {
	margin-top: 10px;
	height: calc(100% - 58px);
    overflow-y: auto;
	position: relative;
}

.landing-dashboard-contents-scroll {
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
}

.blue-cover {
	position: absolute;
	width: 100%;
	height: 100%;
	bottom: 0;
	left: 0;
	right: 0;
	margin-right: 0 !important;
	margin-left: 0 !important;
}

.blue-highlight-media-wrapper {
    width: 100%;
    padding-top: 120%;
    position: relative;
}

.blue-post-media-wrapper {
    width: 100%;
    padding-top: 150%;
    position: relative;
}

.blue-post-media-child {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.blue-post-item-info {
    text-align: left;
    flex: 1 1 auto;
	position: relative;
}

.blue-post-thumb-player {
    width: 100%;
    object-fit: cover;
}

.blue-post-thumb {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.blue-section-title {
	color: white;
	font-weight: 900;
	text-align: left;
	margin-left: 20px;
}

.blue-video-overlay {
	position: absolute;
	margin: 0;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10;
	text-align: left;
	background-color: rgba(0,0,0,.4);
}

@media (max-width: 425px) {
	.blue-nav-placeholder {
		height: 400px;
	}
	.landing-dashboard-contents {
		padding-left: 5px;
		padding-right: 5px;
	}	
}
