.landing-nav {
	position: fixed;
	z-index: 10000;
	width: 100%;
}

.landing-nav-floating {
	z-index: 10000;
	width: 100%;
}

.landing-container {
	padding-top: 0px;
}

.landing-wrapper {
	padding-top: 80px;
	margin: 50px 10px 0px;
}

.landing-header-title {
	font-size: 60px !important;
	line-height: 60px;
	color: white;
}

.landing-header-sub-title {
	font-size: 36px !important;
	line-height: 36px;
	margin-bottom: 20px;
	margin-bottom: 20px;
	text-transform: uppercase;
	color: white;
}

.landing-header-description {
	font-size: 15px !important;
	line-height: 26px !important;
	margin-bottom: 25px !important;
	color: white;
}

.landing-header-subtitle {
	font-size: 15px !important;
	line-height: 26px;
	color: white;
	margin-bottom: 0;
}

.landing-subInfo-wrapper {
	background-color: #242b32 !important;
}

.landing-subInfo {
	margin: 20px 0px;
}

.landing-subInfo-btn {
	margin: 20px 0px;
	text-align: right !important;
}

.landing-header-bg {
	padding: 30% 6.55% 0 6.6%;
    background-size: 100%;
    background-repeat: no-repeat;
}

.landing-downloads-wrapper {
	padding: 30px 10px;
	background-color: #19447F50;
}

.landing-downloads-title {
	font-size: 60px !important;
	line-height: 60px;
}

.landing-downloads-description {
	font-size: 15px !important;
	line-height: 26px !important;
}

.landing-downloads-icon {
	font-size: 25px;
	color: #ED1D24;
}

.landing-downloads-subtitle {
	font-size: 20px !important;
	line-height: 26px;
	margin-bottom: 0;
}

.landing-nav-link a {
	color: #19447f;
	font-family: Open Sans,sans-serif;
	font-size: 12px;
	font-weight: 600;
}

.landing-nav-link a :active {
	color: #000;
}

.intro-inverse {
	display: table;
	width: 100%;
	padding: 0;
	background: url(./img/header-parallax-no-filter.jpg) center center no-repeat;
	/* background-color: rgba(0,0,0,.2) ; */
	-webkit-background-size: cover;
	-moz-background-size: cover;
	background-size: cover;
	-o-background-size: cover;
}
.intro-inverse .overlay {
	background: rgba(0,0,0,0.35);
}
.main-nav-li {
	margin: auto 0px;
}
.main-nav-li .nav-title-str {
	color: #333;
	padding: 8px 15px;
	font-size: 16px;
	line-height: 16px;
	font-weight: 700;
	border-radius: 16px;
}
.main-nav-li .nav-title-str-active {
	background-color: #ccc;
	color: #333;
	padding: 8px 15px;
	font-size: 16px;
	line-height: 16px;
	font-weight: 700;
	border-radius: 16px;
}
.main-nav-li .nav-title-str-sign-up {
	background-color: #F86053;
	color: #fff;
	padding: 8px 15px;
	font-size: 16px;
	line-height: 16px;
	font-weight: 700;
	border-radius: 16px;
}
.landing-wrapper-inverse {
	padding-top: 100px;
	margin: 50px 10px 0px;
	padding-bottom: 30px;
}

.landing-nav-inverse-wrapper {
	position: fixed;
	z-index: 10000;
	width: 100%;
}

.landing-nav-inverse {
	width: 100%;
	background-color: #19447f;
}

.landing-nav-link-inverse a {
	color: #fff;
	font-family: Open Sans,sans-serif;
	font-size: 18px;
	font-weight: 600;
}

.landing-nav-link-inverse a :active {
	color: #fff;
}

.inverse-header-btn {
	margin: 10px 0px;
}

.nav-downloads {
	display: table;
	width: 100%;
	padding: 50px 0;
	background: url(./img/downloads-parallax.jpg) center center no-repeat;
	background-color: #e25249 ;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	background-size: cover;
	-o-background-size: cover;
}

.nav-downloads-image {
	position: relative;
	top: -120px;
	left: 0px;
	margin-bottom: -120px;
	width: 150%;
	height: 500px;
	background: url(./img/downloads-product-image.png) left top no-repeat;
	background-size: auto 500px;
}

#nav-downloads {
	color: white;
}

#nav-downloads h2 {
	font-size: 27px;
	position: relative;
	margin-top: 10px;
	margin-bottom: 15px;
	padding-bottom: 15px;
	text-transform: none;
	color: white;
}

#nav-downloads h2::after {
	position: absolute;
	content: "";
	background: linear-gradient(to right, #fff 0%, #eee 100%);
	height: 4px;
	width: 60px;
	bottom: 0;
	left: 0;
}

/* navReviews Section */
#navReviews {
	padding: 70px 0;
	background: #242B32;
}
#navReviews i {
	color: #e6e6e6;
	font-size: 32px;
	margin-bottom: 20px;
}
#navReviews h2 {
	color: white;
	font-size: 27px;
	position: relative;
	margin-bottom: 15px;
	padding-bottom: 15px;
	text-transform: none;
}
#navReviews h2::after {
	position: absolute;
	content: "";
	background: linear-gradient(to right, #f4645a 0%, #ff857c 100%);
	height: 4px;
	width: 60px;
	bottom: 0;
	margin-left: -30px;
	left: 50%;
}
.navReviews {
	position: relative;
	padding: 20px;
}
.navReviews-image {
	float: left;
	margin-right: 15px;
}
.navReviews-image, .navReviews-image img {
	display: block;
	width: 64px;
	height: 64px;
	border-radius: 50%;
}
.navReviews-content {
	position: relative;
	overflow: hidden;
}
.navReviews-content p {
	margin-bottom: 0;
	font-size: 14px;
	font-style: italic;
	color: white;
}
.navReviews-meta {
	margin-top: 10px;
	font-size: 15px;
	font-weight: 600;
	color: white;
}

#action_title_1 h2 {
	font-size: 27px;
	position: relative;
	margin-bottom: 15px;
	padding-bottom: 15px;
	text-transform: none;
}

#action_title_2 h2 {
	font-size: 27px;
	position: relative;
	margin-bottom: 15px;
	padding-bottom: 15px;
	text-transform: none;
}

#action_title_2 {
	text-align: right;
}

#action_title_1 h2::after {
	position: absolute;
	content: "";
	background: linear-gradient(to right, #f4645a 0%, #ff857c 100%);
	height: 4px;
	width: 60px;
	bottom: 0;
	left: 0;
}

#action_title_2 h2::after {
	position: absolute;
	content: "";
	background: linear-gradient(to right, #f4645a 0%, #ff857c 100%);
	height: 4px;
	width: 60px;
	bottom: 0;
	right: 0;
}

/* Key Features Section */
#keyFeatures {
	padding: 70px 0;
}
#keyFeatures .service-desc {
	margin: 10px 10px 20px;
}
#keyFeatures h2 {
	font-size: 27px;
	position: relative;
	margin-bottom: 15px;
	padding-bottom: 15px;
	text-transform: none;
}
#keyFeatures .section-title h2::after {
	position: absolute;
	content: "";
	background: linear-gradient(to right, #f4645a 0%, #ff857c 100%);
	height: 4px;
	width: 60px;
	bottom: 0;
	margin-left: -30px;
	left: 50%;
}
#keyFeatures i.fa {
	font-size: 22px;
	margin-bottom: 20px;
	margin-left: 10px;
	margin-right: 10px;
	transition: all 0.5s;
	color: #ED1D24;
	width: 48px;
	height: 48px;
	padding: 12px 12px;
	border-radius: 50%;
	border: 1px solid #ccc;
	box-shadow: 10px 10px 10px rgba(0,0,0,.05);
}
#keyFeatures h3 {
	padding: 5px 0;
}
#keyFeatures p {
	line-height: 24px;
	margin: 30px 0;
}
#keyFeatures .keyFeatures-desc {
	margin-bottom: 20px;
}
#keyFeatures .right-title {
	margin-bottom: 10px;
	text-align: right;
	font-size: 14px;
}
#keyFeatures .right-desc {
	margin-bottom: 0px;
	text-align: right;
	font-size: 13px;
}
#keyFeatures .left-title {
	margin-bottom: 10px;
	text-align: left;
	font-size: 14px;
}
#keyFeatures .left-desc {
	margin-bottom: 0px;
	text-align: left;
	font-size: 13px;
}

/* How it works Section */
#works {
	padding: 70px 0;
}
#works h3 {
	font-size: 22px;
	margin: 0 0 20px 0;
}
#works h2 {
	font-size: 27px;
	position: relative;
	margin-bottom: 15px;
	padding-bottom: 15px;
	text-transform: none;
}
#works h2::after {
	position: absolute;
	content: "";
	background: linear-gradient(to right, #f4645a 0%, #ff857c 100%);
	height: 4px;
	width: 60px;
	bottom: 0;
	left: 0;
}
#works .works-text li {
	margin-bottom: 6px;
	margin-left: 6px;
	list-style: none;
	padding: 0;
}

#works .works-text li:before {
	content: '\f00c';
	font-family: 'FontAwesome';
	color: #5ca9fb;
	font-size: 11px;
	font-weight: 300;
	padding-right: 8px;
}
#works img {
	width: 520px;
	margin-top: 10px;
	border-right: 0;
}
#works p {
	line-height: 24px;
	margin: 30px 0;
}
#works i.fa {
	font-size: 30px;
	margin-bottom: 20px;
	transition: all 0.5s;
	color: #fff;
	width: 80px;
	height: 80px;
	padding: 26px 24px;
	border-radius: 50%;
	background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
	box-shadow: 10px 10px 10px rgba(0,0,0,.05);
}

.feature-wrapper {
	margin: 20px auto;
}

.feature-item {
	padding: 10px 20px;
}

.feature-item-icon {
	width: 80px;
	height: 80px;
	margin: 10px auto;
}

.feature-item-title {
	font-size: 14px;
	text-align: center;
}

.feature-item-text {
	font-size: 14px;
}

.quick-setup-title {
	font-size: 50px !important;
	line-height: 50px;
	color: white;
	text-align: center;
	margin-bottom: 15px;
}

.quick-setup-wrapper {
	position: relative;
	width: 100%;
	height: 640px;
}

.quick-setup-image {
	width: 100%;
	height: 640px;
	object-fit: cover;
}

.quick-setup-overlay {
	position: absolute;
	padding: 50px 30px;
	margin: 0;
	width: 100%;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10;
	text-align: left;
	background-color: rgba(0,53,38,.8);
}

.quick-setup-inner {
	background-color: white;
	box-shadow: 0 0 5px rgba(0,0,0,0.15);
	border: 1px solid rgb(87, 87, 87,0.15);
	border-radius: 5px;
	overflow: hidden;
	display: flex;
	flex-flow: row;
}

.quick-setup-side {
	display: 'inline-block';
	width: 300px;
	border-right: 1px solid rgba(0,0,0,0.25);
	padding: 10px 15px;
}

.quick-setup-header {
	flex: 1 1 auto;
}

.quick-setup-side-header {
	display: flex;
	flex-flow: row;
}

.quick-setup-side-header-icon {
	flex: 0 0 auto;
	width: 45px;
	height: 45px;
	border-radius: 3px;
	border: 1px solid rgba(0,0,0,0.25);
}

.quick-setup-side-header-title {
	flex: 1 1 auto;
	margin: 10px 15px;
	font-size: 16px;
}

.quick-setup-side-header-subtitle {
	font-size: 12px;
	padding: 20px 5px;
	margin-bottom: 20px;
	border-bottom: 1px solid rgba(0,0,0,0.25);
}

.quick-setup-header-inner {
	border-top: 1px solid rgba(0,0,0,0.25);;
	padding: 5px 15px;
	max-height: 500px;
	overflow-y: auto;
}

.quick-setup-header-title {
	font-size: 16px;
	color: #000;
	margin: 10px 0px;
}

@media (max-width: 1024px) {
    
	.nav-downloads-image {
		top: -50px;
		margin-bottom: -50px;
		left: 0px;
		width: 110%;
		height: 400px;
		background: url(./img/downloads-product-image.png) left top no-repeat;
		background-size: auto 400px;
	}

}
	
@media (max-width: 768px) {

	.nav-downloads-image {
		top: -50px;
		margin-bottom: -50px;
		left: 0px;
		width: 110%;
		height: 400px;
		background: url(./img/downloads-product-image.png) left top no-repeat;
		background-size: auto 400px;
	}

}
	
@media (max-width: 425px) {
		
	.quick-setup-wrapper {
		height: 1180px;
	}

	.quick-setup-image {
		height: 1180px;
	}

	.quick-setup-inner {
		flex-flow: column;
	}

	.quick-setup-side {
		width: 100%;
		border-right: none;
		border-bottom: 1px solid rgba(0,0,0,0.25);
	}
	
	.main-nav-li {
		margin: 10px 0px;
	}
	
	.landing-container {
		padding-top: 60px;
	}

	.landing-wrapper {
		padding-top: 20px;
		text-align: center;
	}

	.landing-header-title {
		font-size: 40px !important;
		line-height: 40px;
		margin-bottom: 15px !important;
	}

	.landing-header-sub-title {
		font-size: 24px !important;
		line-height: 24px;
		margin-bottom: 15px !important;
	}

	.landing-header-description {
		font-size: 15px !important;
		line-height: 20px !important;
		margin-bottom: 25px !important;
	}

	.nav-downloads-image {
		top: 0px;
		height: 300px;
		background: url(./img/downloads-product-image.png) left top no-repeat;
		background-size: auto 300px;
	}

}
