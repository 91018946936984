.wallet-layout {
    display: inline-block;
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 10;
}

.wallet-icon {
    width: 45px;
    height: 45px;
}

.wallet-unread {
    position: absolute;
    width: 18px;
    height: 18px;
    background-color: coral;
    color: white;
    border-radius: 9px;
    top: 0px;
    right: 0px;
    font-size: 12px;
    font-weight: 900;
    text-align: center;
}

.wallet-tile {
    display: inline-block;
    position: fixed;
    bottom: 10px;
    right: 10px;
    min-width: 400px;
	margin-top: 10px;
	margin-left: 10px;
	margin-right: 10px;
	margin-bottom: 10px;
	box-shadow: 0 0 5px rgba(0,0,0,0.15);
	border: 1px solid rgb(87, 87, 87,0.15);
    background-color: white;
	border-radius: 5px;
	overflow: hidden;
    z-index: 10;
}

.wallet-info-tile {
    display: inline-block;
    width: calc(100% - 20px);
	margin-top: 10px;
	margin-left: 10px;
	margin-right: 10px;
	margin-bottom: 10px;
	box-shadow: 0 0 5px rgba(0,0,0,0.15);
	border: 1px solid rgb(87, 87, 87,0.15);
    background-color: white;
	border-radius: 5px;
	overflow: hidden;
    max-height: 500px;
}

.wallet-icon-opened {
    width: 45px;
    height: 45px;
    margin-left: 15px;
}

.wallet-title-bar {
    display: flex;
    flex-flow: row;
    margin: 10px 15px;
}

.wallet-margin {
    margin: 10px 15px;
}

.wallet-title-icon {
    flex: 0 0 auto;
    width: 45px;
    height: 45px;
}

.wallet-title {
    flex: 1 1 auto;
    margin: auto 10px;
    font-size: 17px;
}

.wallet-title-action {
    flex: 0 0 auto;
    margin-right: 0px;
    width: 40px;
    height: 40px;
    border-radius: 20px;
}

.wallet-transactions-list {
    width: 100%;
    max-height: 600px;
    overflow-y: auto;
    scrollbar-width: 4px;
}

.wallet-table-title {
	margin: 10px 10px;
	font-size: 14px;
	text-align: center;
}

.wallet-table-info {
	margin: 10px 10px;
	font-size: 12px;
    font-weight: 300;
	text-align: center;
}
