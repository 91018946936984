.main-overlay {
	background-color: rgba(0,0,0,.7);
	position: fixed;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	z-index: 999999999999999;
}

.main-overlay-wrapper {
	background-color: white;
	position: relative;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 640px;
	max-height: 90%;
	overflow-y: auto;
	text-align: center;
	border-radius: 8px;
	padding: 20px 30px;
}

.main-overlay-msg {
	color: #333;
	font-size: 14px;
}

.main-overlay-policy-wrapper {
	height: 70vh;
}

.main-overlay-policy {
	height: calc(100% - 100px);
	overflow-x: hidden;
	overflow-y: auto;
}

@media (max-width: 768px) {
    .main-overlay-wrapper {
        width: 90%;
    }
    
}
	
@media (max-width: 425px) {
}