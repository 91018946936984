.game-overlay-events {
    display: inline-block;
    position: absolute;
    bottom: 10px;
    right: 48px;
    overflow-y: scroll;
    background-color: white;
    border-radius: 4px;
    padding: 0 10px;
    height: calc(100% - 100px);
}

.game-overlay-btn-icon {
    display: inline-block;
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 36px;
    height: 36px;
}

.game-overlay {
    display: inline-block;
    position: absolute;
    top: 10px;
    right: 0px;
    margin-right: calc(50% - 172px);
}

.game-overlay-icon {
    display: inline-block;
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    background-color: white;
    border: 1px solid black;
    z-index: 100000;
    vertical-align: middle;
}

.game-overlay-info-left {
    display: inline-block;
    width: 120px;
    height: 24px;
    margin-left: -12px;
    padding-left: 16px;
    padding-right: 4px;
    text-align: center;
    font-size: 12px;
    font-weight: 800;
    padding-top: 3px;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    vertical-align: middle;
}

.game-overlay-info-right {
    display: inline-block;
    width: 120px;
    height: 24px;
    margin-right: -12px;
    padding-left: 4px;
    padding-right: 16px;
    text-align: center;
    font-size: 12px;
    font-weight: 800;
    padding-top: 3px;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    vertical-align: middle;
}

.game-overlay-info-center {
    display: inline-block;
    vertical-align: middle;
}

.game-overlay-info-scores {
    display: inline-block;
    height: 32px;
    margin: 5px 0;
}

.game-overlay-info-score {
    display: inline-block;
    width: 37px;
    height: 32px;
    border-radius: 4px;
    text-align: center;
    font-weight: 800;
    font-size: 20px;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
}

.game-overlay-info-tile {
    width: 80px;
    height: 20px;
    border-radius: 9px;
    text-align: center;
    font-size: 10px;
    padding-top: 3px;
    font-weight: 800;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
}