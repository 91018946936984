.enroll-overlay {
	background-color: rgba(0,0,0,.7);
	position: fixed;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	z-index: 999999999999999;
}

.enroll-overlay-wrapper {
	background-color: white;
	position: relative;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 640px;
	max-height: 80%;
	overflow-y: auto;
	text-align: center;
	border-radius: 8px;
	padding: 20px 30px;
    display: flex;
    flex-flow: column;
}

.enroll-overlay-title {
    flex: 0 0 auto;
    font-size: 24px;
    text-align: center;
    height: 30px;
    margin: 15px 10px;
}

.enroll-overlay-text {
    flex: 0 0 auto;
    font-size: 16px;
    text-align: left;
    margin: 5px 10px;
}

.enroll-overlay-actions {
    flex: 0 0 auto;
    width: 100%;
}

.enroll-list-item {
    display: flex;
    flex-flow: row;
    padding: 10px 0px;
    border-bottom: 1px solid rgba(0,0,0,.2);
    text-align: left;
}

.enroll-list-icon {
    flex: 0 0 auto;
    width: 24px;
    height: 24px;
    border-radius: 12px;
}

.enroll-list-info {
    flex: 1 1 auto;
    padding: 4px 10px;
    font-size: 16px;
}

.enroll-list-sub-info {
    flex: 0 0 auto;
    font-size: 16px;
}

.enroll-overlay-contents {
    flex: 1 1 auto;
    width: 100%;
    margin: 10px 0px;
    padding: 0px 20px;
    padding: 0px 20px;
}

@media (max-width: 1024px) {
    
}
	
@media (max-width: 768px) {
    
	.enroll-overlay-wrapper {
		width: 420px;
	}
	
}
	
@media (max-width: 425px) {
    
	.enroll-overlay-wrapper {
		width: 360px;
	}
	
}