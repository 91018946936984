
.detail-header {
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
}
  
.wrap-content-reduced {
    display: flex;
    flex-flow: row;
    margin-right: 15px;
}
  
.wrap-contents-parent {
    display: flex;
    flex-flow: row;
}
  
.wrap-content-parent {
    display: flex;
    flex-flow: row;
    width: 100%
}
  
.wrap-content-wrap-child {
    flex: 0 0 auto;
    margin-right: 5px;
}

.wrap-content-fill-child {
    flex: 1 1 auto;
    margin-right: 5px;
}
  
.wrap-content-parent-c {
    display: flex;
    flex-flow: row;
}
  
.wrap-content-wrap-child-c {
    flex: 0 0 auto;
}
  
.wrap-content-fill-child-c {
    flex: 1 1 auto;
}

.wrap-content-parent-fixed {
    display: flex;
    flex-flow: row;
    height: 100vh;
}
  
.wrap-content-wrap-child-fixed {
    flex: 0 0 auto;
    height: 100vh;
}
  
.wrap-content-fill-child-fixed {
    flex: 1 1 auto;
    height: 100vh;
    overflow-y: auto;
}

.wrap-content-parent-h {
    display: flex;
    flex-flow: column;
    height: 100%
}

.wrap-content-wrap-child-h {
    flex: 0 0 auto;
}

.wrap-content-fill-child-h {
    flex: 1 1 auto;
}

.overlay-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 2000;
    background-color: rgba(0,0,0,0.3);
}

.overlay-modal {
    position: absolute;
    top: 10%;
    left: calc(50% - 512px);
    width: 1024px;
    height: 80%;
    background-color: white;
	box-shadow: 0 0 5px rgba(0,0,0,0.15);
	border: 1px solid rgb(87, 87, 87,0.15);
	border-radius: 5px;
	overflow: hidden;
    padding: 20px 15px;
}

.profile-thumb {
    object-fit: cover;
}

::-webkit-scrollbar {
    width: 2px;
    height: 2px;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}


@media (max-width: 1024px) {
	
    .overlay-modal {
        left: 10%;
        width: 80%;
        padding: 20px 15px;
    }

}

@media (max-width: 425px) {
	
    .overlay-modal {
        left: 10px;
        width: calc(100% - 20px);
        padding: 20px 10px;
    }

}
