body, html {
	font-family: 'Open Sans', sans-serif;
	text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased !important;
	color: #777;
	font-weight: 400;
	width: 100% !important;
	height: 100% !important;
}
h2, h3, h4 {
	font-family: 'Raleway', sans-serif;
}
h2 {
	text-transform: uppercase;
	margin: 0 0 20px 0;
	font-weight: 800;
	font-size: 36px;
	color: #333;
}
h3 {
	font-size: 20px;
	font-weight: 600;
	color: #333;
}
h4 {
	font-size: 18px;
	color: #333;
	font-weight: 600;
}
h5 {
	text-transform: uppercase;
	font-weight: 700;
	line-height: 20px;
}
p {
	font-size: 15px;
}
p.intro {
	margin: 12px 0 0;
	line-height: 24px;
}
a {
	color: #608dfd;
	font-weight: 400;
}
a:hover, a:focus {
	text-decoration: none;
	color: #608dfd;
}

hr {
	height: 2px;
	width: 70px;
	text-align: center;
	position: relative;
	background: #1E7A46;
	margin-bottom: 20px;
	border: 0;
}
/* Navigation */
#menu {
	padding: 15px;
	transition: all 0.8s;
}
#menu.navbarX-default {
	background-color: #fff;
	border-color: rgba(231, 231, 231, 0);
	box-shadow: 0 0 10px rgba(0,0,0,0.15)
}
#menu a.navbarX-brand {
	font-family: 'Raleway', sans-serif;
	font-size: 24px;
	font-weight: 700;
	color: #333;
	text-transform: uppercase;
}
#menu.navbarX-default .navbarX-nav > li > a {
	font-family: 'Lato', sans-serif;
	text-transform: uppercase;
	color: #555;
	font-size: 15px;
	font-weight: 400;
	padding: 8px 2px;
	border-radius: 0;
	margin: 9px 20px 0 20px;
}
#menu.navbarX-default .navbarX-nav > li > a:after {
	display: block;
	position: absolute;
	left: 0;
	bottom: -1px;
	width: 0;
	height: 2px;
	background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
	content: "";
	transition: width 0.2s;
}
#menu.navbarX-default .navbarX-nav > li > a:hover:after {
	width: 100%;
}
.navbarX-default .navbarX-nav > .active > a, .navbarX-default .navbarX-nav > .active > a:hover, .navbarX-default .navbarX-nav > .active > a:focus {
	background-color: transparent;
}
.navbarX-default .navbarX-nav > .active > a:after, .navbarX-default .navbarX-nav > .active > a:hover:after, .navbarX-default .navbarX-nav > .active > a:focus:after {
	display: block !important;
	position: absolute !important;
	left: 0 !important;
	bottom: -1px !important;
	width: 100% !important;
	height: 2px !important;
	background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%) !important;
	content: "" !important;
	transition: width 0.2s !important;
}
.navbarX-toggle {
	border-radius: 0;
}
.navbarX-default .navbarX-toggle:hover, .navbarX-default .navbarX-toggle:focus {
	background-color: #fff;
	border-color: #608dfd;
}
.navbarX-default .navbarX-toggle:hover>.icon-bar {
	background-color: #608dfd;
}
.section-title {
	margin-bottom: 70px;
}
.section-title h2 {
	position: relative;
	margin-top: 10px;
	margin-bottom: 15px;
	padding-bottom: 15px;
}
.section-title h2::after {
	position: absolute;
	content: "";
	background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
	height: 4px;
	width: 60px;
	bottom: 0;
	margin-left: -30px;
	left: 50%;
}
.section-title p {
	font-size: 18px;
}
.btn-custom {
	font-family: 'Raleway', sans-serif;
	text-transform: uppercase;
	color: #fff;
	background-color: #5ca9fb;
	background-image: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
	padding: 14px 34px;
	letter-spacing: 1px;
	margin: 0;
	font-size: 15px;
	font-weight: 500;
	border-radius: 25px;
	transition: all 0.5s linear;
	border: 0;
}
.btn-custom:hover, .btn-custom:focus, .btn-custom.focus, .btn-custom:active, .btn-custom.active {
	color: #fff;
	background-image: none;
	background-color: #6372ff;
}
.btn:active, .btn.active {
	background-image: none;
	outline: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
}
a:focus, .btn:focus, .btn:active:focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn.active.focus {
	outline: none;
	outline-offset: none;
}
/* Header Section */
.intro {
	display: table;
	width: 100%;
	padding: 0;
	background: url(./img/header-parallax.png) center center no-repeat;
	background-color: #19447F ;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	background-size: cover;
	-o-background-size: cover;
}
.intro .overlay {
	background: rgba(0,0,0,0.0);
}
.intro h1 {
	font-family: 'Raleway', sans-serif;
	color: #fff;
	font-size: 82px;
	font-weight: 700;
	text-transform: uppercase;
	margin-top: 0px;
	margin-bottom: 30px;
}
.intro h1 span {
	font-weight: 800;
	color: #5ca9fb;
}
.intro p {
	color: #fff;
	font-size: 22px;
	font-weight: 300;
	line-height: 30px;
	margin: 0 auto;
	margin-bottom: 60px;
}
.quote {
	display: table;
	width: 100%;
	padding: 0;
	background: url(./img/quote-parallax.png) center center no-repeat;
	background-color: #19447F ;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	background-size: cover;
	-o-background-size: cover;
}
.quote .overlay {
	background: rgba(0,0,0,0.0);
}
.quote h1 {
	font-family: 'Raleway', sans-serif;
	color: #fff;
	font-size: 65px;
	font-weight: 700;
	text-transform: uppercase;
	margin-top: 50px;
	margin-bottom: 30px;
}
.quote h1 span {
	font-weight: 800;
	color: #5ca9fb;
}
.quote p {
	color: #fff;
	font-size: 22px;
	font-weight: 300;
	line-height: 30px;
	margin: 0 auto;
	margin-bottom: 60px;
}
header .intro-text {
	padding-top: 350px;
	padding-bottom: 200px;
	text-align: center;
}
/* Features Section */
#features {
	padding: 70px 0;
	background: #f6f6f6;
}
#features i.fa {
	font-size: 38px;
	margin-bottom: 20px;
	transition: all 0.5s;
	color: #fff;
	width: 100px;
	height: 100px;
	padding: 30px 0;
	border-radius: 50%;
	background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
	box-shadow: 10px 10px 10px rgba(0,0,0,.05);
}
/* About Section */
#about {
	padding: 70px 0;
}
#about h3 {
	font-size: 22px;
	margin: 0 0 20px 0;
}
#about h2 {
	position: relative;
	margin-bottom: 15px;
	padding-bottom: 15px;
}
#about h2::after {
	position: absolute;
	content: "";
	background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
	height: 4px;
	width: 60px;
	bottom: 0;
	left: 0;
}
#about .about-text li {
	margin-bottom: 6px;
	margin-left: 6px;
	list-style: none;
	padding: 0;
}
#about .about-text li:before {
	content: '\f00c';
	font-family: 'FontAwesome';
	color: #5ca9fb;
	font-size: 11px;
	font-weight: 300;
	padding-right: 8px;
}
#about img {
	width: 520px;
	margin-top: 10px;
	border-right: 0;
}
#about p {
	line-height: 24px;
	margin: 30px 0;
}
#about i.fa {
	font-size: 30px;
	margin-bottom: 20px;
	transition: all 0.5s;
	color: #fff;
	width: 80px;
	height: 80px;
	padding: 26px 24px;
	border-radius: 50%;
	background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
	box-shadow: 10px 10px 10px rgba(0,0,0,.05);
}
/* Services Section */
#services {
	padding: 70px 0;
	background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
	color: #fff;
}
#services .service-desc {
	margin: 10px 10px 20px;
}
#services h2 {
	color: #fff;
}
#services .section-title h2::after {
	position: absolute;
	content: "";
	background: rgba(255,255,255,.3);
	height: 4px;
	width: 60px;
	bottom: 0;
	margin-left: -30px;
	left: 50%;
}
#services i.fa {
	font-size: 42px;
	width: 120px;
	height: 120px;
	padding: 40px 0;
	background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
	border-radius: 50%;
	color: #fff;
	box-shadow: 10px 10px 10px rgba(0,0,0,.05);
}
#services h3 {
	font-weight: 500;
	padding: 5px 0;
	color: #fff;
}
#services p {
	color: rgba(255,255,255,.75);
}
#services .service-desc {
	margin-bottom: 40px;
}
/* Action Section */
#actions {
	padding: 70px 0;
}
/* Portfolio Section */
#portfolio {
	padding: 70px 0;
}
.portfolio-item {
	margin: 1px -15px 0 -14px;
	padding: 0;
}
.portfolio-item .hover-bg {
	overflow: hidden;
	position: relative;
	margin: 0;
}
.hover-bg .hover-text {
	position: absolute;
	text-align: center;
	margin: 0 auto;
	color: #fff;
	background: linear-gradient(to right, rgba(99,114,255,0.8) 0%, rgba(92,169,251,0.8) 100%);
	padding: 30% 0 0 0;
	height: 100%;
	width: 100%;
	opacity: 0;
	transition: all 0.5s;
}
.hover-bg .hover-text>h4 {
	opacity: 0;
	color: #fff;
	-webkit-transform: translateY(100%);
	transform: translateY(100%);
	transition: all 0.3s;
	font-size: 18px;
	letter-spacing: 1px;
	font-weight: 500;
	text-transform: uppercase;
}
.hover-bg:hover .hover-text>h4 {
	opacity: 1;
	-webkit-backface-visibility: hidden;
	-webkit-transform: translateY(0);
	transform: translateY(0);
}
.hover-bg:hover .hover-text {
	opacity: 1;
}
/* Testimonials Section */
#testimonials {
	padding: 70px 0;
	background: #f6f6f6;
}
#testimonials i {
	color: #e6e6e6;
	font-size: 32px;
	margin-bottom: 20px;
}
.testimonial {
	position: relative;
	padding: 20px;
}
.testimonial-image {
	float: left;
	margin-right: 15px;
}
.testimonial-image, .testimonial-image img {
	display: block;
	width: 64px;
	height: 64px;
	border-radius: 50%;
}
.testimonial-content {
	position: relative;
	overflow: hidden;
}
.testimonial-content p {
	margin-bottom: 0;
	font-size: 14px;
	font-style: italic;
}
.testimonial-meta {
	margin-top: 10px;
	font-size: 15px;
	font-weight: 600;
	color: #666;
}
/* Team Section */
#team {
	padding: 70px 0;
}
#team h4 {
	margin: 5px 0;
}
#team .team-img {
	width: 240px;
}
#team .thumbnail {
	background: transparent;
	border: 0;
}
#team .thumbnail .caption {
	padding: 10px 0 0 0;
	color: #888;
}
/* Contact Section */
#contact {
	padding: 100px 0 60px 0;
	/*background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);*/
	/* background: linear-gradient(to right, #f4645a 0%, #ff857c 100%); */
	background-color: #19447F;
	color: rgba(255,255,255,.75);
}
#contact .section-title {
	margin-bottom: 40px;
}
#contact .section-title p {
	font-size: 16px;
}
#contact h2 {
	color: #fff;
	margin-top: 10px;
	margin-bottom: 15px;
	padding-bottom: 15px;
}
#contact .section-title h2::after {
	position: absolute;
	content: "";
	background: rgba(255,255,255,.3);
	height: 4px;
	width: 60px;
	bottom: 0;
	left: 30px;
}
#contact h3 {
	color: #fff;
	margin-top: 80px;
	margin-bottom: 25px;
	padding-bottom: 20px;
	font-weight: 400;
}
#contact form {
	padding-top: 20px;
}
#contact .text-danger {
	color: #cc0033;
	text-align: left;
}
#contact .btn-custom {
	margin: 30px 0;
	background: transparent;
	border: 2px solid #fff;
}
#contact .btn-custom:hover {
	color: #1f386e;
	background: #fff;
}
label {
	font-size: 12px;
	font-weight: 400;
	font-family: 'Open Sans', sans-serif;
	float: left;
}
#contact .form-control {
	display: block;
	width: 100%;
	padding: 6px 12px;
	font-size: 16px;
	line-height: 1.42857143;
	color: #444;
	background-color: #fff;
	background-image: none;
	border: 1px solid #ddd;
	border-radius: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
	-webkit-transition: none;
	-o-transition: none;
	transition: none;
}
#contact .form-control:focus {
	border-color: #999;
	outline: 0;
	-webkit-box-shadow: transparent;
	box-shadow: transparent;
}
.form-control::-webkit-input-placeholder {
color: #777;
}
.form-control:-moz-placeholder {
color: #777;
}
.form-control::-moz-placeholder {
color: #777;
}
.form-control:-ms-input-placeholder {
color: #777;
}
#contact .contact-item {
	margin: 20px 0;
}
#contact .contact-item span {
	color: rgba(255,255,255,1);
	margin-bottom: 10px;
	display: block;
}
#contact .contact-item i.fa {
	margin-right: 10px;
}
#contact .social {
	border-top: 1px solid rgba(255,255,255,0.15);
	padding-top: 50px;
	margin-top: 50px;
	text-align: center;
}
#contact .social ul li {
	display: inline-block;
	margin: 0 20px;
}
#contact .social i.fa {
	font-size: 22px;
	width: 48px;
	height: 48px;
	padding: 12px 0;
	border: 2px solid #fff;
	color: #fff;
	border-radius: 50%;
	transition: all 0.3s;
}
#contact .social i.fa:hover {
	color: #608dfd;
	background: #fff;
}
/* Footer Section*/
#footer {
	background: #f6f6f6;
	padding: 30px 0;
}
#footer p {
	color: #888;
	font-size: 14px;
}
#footer a {
	color: #608dfd;
}
#footer a:hover {
	border-bottom: 2px solid #608dfd;
}

@media (max-width: 768px) {
#about img {
	margin: 50px 0;
}
}

@media (min-width: 768px) {
	.navbarX-header {
	  float: left;
	}
  }
  .navbarX-collapse {
	padding-right: 15px;
	padding-left: 15px;
	overflow-x: visible;
	-webkit-overflow-scrolling: touch;
	border-top: 1px solid transparent;
	-webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1);
			box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1);
  }
  .navbarX-collapse.in {
	overflow-y: auto;
  }
  @media (min-width: 768px) {
	.navbarX-collapse {
	  width: auto;
	  border-top: 0;
	  -webkit-box-shadow: none;
			  box-shadow: none;
	}
	.navbarX-collapse.collapse {
	  display: block !important;
	  height: auto !important;
	  padding-bottom: 0;
	  overflow: visible !important;
	}
	.navbarX-collapse.in {
	  overflow-y: visible;
	}
	.navbarX-fixed-top .navbarX-collapse,
	.navbarX-static-top .navbarX-collapse,
	.navbarX-fixed-bottom .navbarX-collapse {
	  padding-right: 0;
	  padding-left: 0;
	}
  }
  .navbarX-fixed-top .navbarX-collapse,
  .navbarX-fixed-bottom .navbarX-collapse {
	max-height: 340px;
  }
  @media (max-device-width: 480px) and (orientation: landscape) {
	.navbarX-fixed-top .navbarX-collapse,
	.navbarX-fixed-bottom .navbarX-collapse {
	  max-height: 200px;
	}
  }
  .container > .navbarX-header,
  .container-fluid > .navbarX-header,
  .container > .navbarX-collapse,
  .container-fluid > .navbarX-collapse {
	margin-right: -15px;
	margin-left: -15px;
  }
  @media (min-width: 768px) {
	.container > .navbarX-header,
	.container-fluid > .navbarX-header,
	.container > .navbarX-collapse,
	.container-fluid > .navbarX-collapse {
	  margin-right: 0;
	  margin-left: 0;
	}
  }
  .navbarX-static-top {
	z-index: 1000;
	border-width: 0 0 1px;
  }
  @media (min-width: 768px) {
	.navbarX-static-top {
	  border-radius: 0;
	}
  }
  .navbarX-fixed-top,
  .navbarX-fixed-bottom {
	position: fixed;
	right: 0;
	left: 0;
	z-index: 1030;
  }
  @media (min-width: 768px) {
	.navbarX-fixed-top,
	.navbarX-fixed-bottom {
	  border-radius: 0;
	}
  }
  .navbarX-fixed-top {
	top: 0;
	border-width: 0 0 1px;
  }
  .navbarX-fixed-bottom {
	bottom: 0;
	margin-bottom: 0;
	border-width: 1px 0 0;
  }
  .navbarX-brand {
	float: left;
	height: 50px;
	padding: 15px 15px;
	font-size: 18px;
	line-height: 20px;
  }
  .navbarX-brand:hover,
  .navbarX-brand:focus {
	text-decoration: none;
  }
  .navbarX-brand > img {
	display: block;
  }
  @media (min-width: 768px) {
	.navbar > .container .navbarX-brand,
	.navbar > .container-fluid .navbarX-brand {
	  margin-left: -15px;
	}
  }
  .navbarX-toggle {
	position: relative;
	float: right;
	padding: 9px 10px;
	margin-top: 8px;
	margin-right: 15px;
	margin-bottom: 8px;
	background-color: transparent;
	background-image: none;
	border: 1px solid transparent;
	border-radius: 4px;
  }
  .navbarX-toggle:focus {
	outline: 0;
  }
  .navbarX-toggle .icon-bar {
	display: block;
	width: 22px;
	height: 2px;
	border-radius: 1px;
  }
  .navbarX-toggle .icon-bar + .icon-bar {
	margin-top: 4px;
  }
  @media (min-width: 768px) {
	.navbarX-toggle {
	  display: none;
	}
  }
  .navbarX-nav {
	margin: 7.5px -15px;
  }
  .navbarX-nav > li > a {
	padding-top: 10px;
	padding-bottom: 10px;
	line-height: 20px;
  }
  @media (max-width: 767px) {
	.navbarX-nav .open .dropdown-menu {
	  position: static;
	  float: none;
	  width: auto;
	  margin-top: 0;
	  background-color: transparent;
	  border: 0;
	  -webkit-box-shadow: none;
			  box-shadow: none;
	}
	.navbarX-nav .open .dropdown-menu > li > a,
	.navbarX-nav .open .dropdown-menu .dropdown-header {
	  padding: 5px 15px 5px 25px;
	}
	.navbarX-nav .open .dropdown-menu > li > a {
	  line-height: 20px;
	}
	.navbarX-nav .open .dropdown-menu > li > a:hover,
	.navbarX-nav .open .dropdown-menu > li > a:focus {
	  background-image: none;
	}
  }
  @media (min-width: 768px) {
	.navbarX-nav {
	  float: left;
	  margin: 0;
	}
	.navbarX-nav > li {
	  float: left;
	}
	.navbarX-nav > li > a {
	  padding-top: 15px;
	  padding-bottom: 15px;
	}
  }
  .navbarX-form {
	padding: 10px 15px;
	margin-top: 8px;
	margin-right: -15px;
	margin-bottom: 8px;
	margin-left: -15px;
	border-top: 1px solid transparent;
	border-bottom: 1px solid transparent;
	-webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1), 0 1px 0 rgba(255, 255, 255, .1);
			box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1), 0 1px 0 rgba(255, 255, 255, .1);
  }
  @media (min-width: 768px) {
	.navbarX-form .form-group {
	  display: inline-block;
	  margin-bottom: 0;
	  vertical-align: middle;
	}
	.navbarX-form .form-control {
	  display: inline-block;
	  width: auto;
	  vertical-align: middle;
	}
	.navbarX-form .form-control-static {
	  display: inline-block;
	}
	.navbarX-form .input-group {
	  display: inline-table;
	  vertical-align: middle;
	}
	.navbarX-form .input-group .input-group-addon,
	.navbarX-form .input-group .input-group-btn,
	.navbarX-form .input-group .form-control {
	  width: auto;
	}
	.navbarX-form .input-group > .form-control {
	  width: 100%;
	}
	.navbarX-form .control-label {
	  margin-bottom: 0;
	  vertical-align: middle;
	}
	.navbarX-form .radio,
	.navbarX-form .checkbox {
	  display: inline-block;
	  margin-top: 0;
	  margin-bottom: 0;
	  vertical-align: middle;
	}
	.navbarX-form .radio label,
	.navbarX-form .checkbox label {
	  padding-left: 0;
	}
	.navbarX-form .radio input[type="radio"],
	.navbarX-form .checkbox input[type="checkbox"] {
	  position: relative;
	  margin-left: 0;
	}
	.navbarX-form .has-feedback .form-control-feedback {
	  top: 0;
	}
  }
  @media (max-width: 767px) {
	.navbarX-form .form-group {
	  margin-bottom: 5px;
	}
	.navbarX-form .form-group:last-child {
	  margin-bottom: 0;
	}
  }
  @media (min-width: 768px) {
	.navbarX-form {
	  width: auto;
	  padding-top: 0;
	  padding-bottom: 0;
	  margin-right: 0;
	  margin-left: 0;
	  border: 0;
	  -webkit-box-shadow: none;
			  box-shadow: none;
	}
  }
  .navbarX-nav > li > .dropdown-menu {
	margin-top: 0;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
  }
  .navbarX-fixed-bottom .navbarX-nav > li > .dropdown-menu {
	margin-bottom: 0;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
  }
  .navbarX-btn {
	margin-top: 8px;
	margin-bottom: 8px;
  }
  .navbarX-btn.btn-sm {
	margin-top: 10px;
	margin-bottom: 10px;
  }
  .navbarX-btn.btn-xs {
	margin-top: 14px;
	margin-bottom: 14px;
  }
  .navbarX-text {
	margin-top: 15px;
	margin-bottom: 15px;
  }
  @media (min-width: 768px) {
	.navbarX-text {
	  float: left;
	  margin-right: 15px;
	  margin-left: 15px;
	}
  }
  @media (min-width: 768px) {
	.navbarX-left {
	  float: left !important;
	}
	.navbarX-right {
	  float: right !important;
	  margin-right: -15px;
	}
	.navbarX-right ~ .navbarX-right {
	  margin-right: 0;
	}
  }
  .navbarX-default {
	background-color: #f8f8f8;
	border-color: #e7e7e7;
  }
  .navbarX-default .navbarX-brand {
	color: #777;
  }
  .navbarX-default .navbarX-brand:hover,
  .navbarX-default .navbarX-brand:focus {
	color: #5e5e5e;
	background-color: transparent;
  }
  .navbarX-default .navbarX-text {
	color: #777;
  }
  .navbarX-default .navbarX-nav > li > a {
	color: #777;
  }
  .navbarX-default .navbarX-nav > li > a:hover,
  .navbarX-default .navbarX-nav > li > a:focus {
	color: #333;
	background-color: transparent;
  }
  .navbarX-default .navbarX-nav > .active > a,
  .navbarX-default .navbarX-nav > .active > a:hover,
  .navbarX-default .navbarX-nav > .active > a:focus {
	color: #555;
	background-color: #e7e7e7;
  }
  .navbarX-default .navbarX-nav > .disabled > a,
  .navbarX-default .navbarX-nav > .disabled > a:hover,
  .navbarX-default .navbarX-nav > .disabled > a:focus {
	color: #ccc;
	background-color: transparent;
  }
  .navbarX-default .navbarX-toggle {
	border-color: #ddd;
  }
  .navbarX-default .navbarX-toggle:hover,
  .navbarX-default .navbarX-toggle:focus {
	background-color: #ddd;
  }
  .navbarX-default .navbarX-toggle .icon-bar {
	background-color: #888;
  }
  .navbarX-default .navbarX-collapse,
  .navbarX-default .navbarX-form {
	border-color: #e7e7e7;
  }
  .navbarX-default .navbarX-nav > .open > a,
  .navbarX-default .navbarX-nav > .open > a:hover,
  .navbarX-default .navbarX-nav > .open > a:focus {
	color: #555;
	background-color: #e7e7e7;
  }
  @media (max-width: 767px) {
	.navbarX-default .navbarX-nav .open .dropdown-menu > li > a {
	  color: #777;
	}
	.navbarX-default .navbarX-nav .open .dropdown-menu > li > a:hover,
	.navbarX-default .navbarX-nav .open .dropdown-menu > li > a:focus {
	  color: #333;
	  background-color: transparent;
	}
	.navbarX-default .navbarX-nav .open .dropdown-menu > .active > a,
	.navbarX-default .navbarX-nav .open .dropdown-menu > .active > a:hover,
	.navbarX-default .navbarX-nav .open .dropdown-menu > .active > a:focus {
	  color: #555;
	  background-color: #e7e7e7;
	}
	.navbarX-default .navbarX-nav .open .dropdown-menu > .disabled > a,
	.navbarX-default .navbarX-nav .open .dropdown-menu > .disabled > a:hover,
	.navbarX-default .navbarX-nav .open .dropdown-menu > .disabled > a:focus {
	  color: #ccc;
	  background-color: transparent;
	}
  }
  .navbarX-default .navbarX-link {
	color: #777;
  }
  .navbarX-default .navbarX-link:hover {
	color: #333;
  }
  .navbarX-default .btn-link {
	color: #777;
  }
  .navbarX-default .btn-link:hover,
  .navbarX-default .btn-link:focus {
	color: #333;
  }
  .navbarX-default .btn-link[disabled]:hover,
  fieldset[disabled] .navbarX-default .btn-link:hover,
  .navbarX-default .btn-link[disabled]:focus,
  fieldset[disabled] .navbarX-default .btn-link:focus {
	color: #ccc;
  }
  .navbarX-inverse {
	background-color: #222;
	border-color: #080808;
  }
  .navbarX-inverse .navbarX-brand {
	color: #9d9d9d;
  }
  .navbarX-inverse .navbarX-brand:hover,
  .navbarX-inverse .navbarX-brand:focus {
	color: #fff;
	background-color: transparent;
  }
  .navbarX-inverse .navbarX-text {
	color: #9d9d9d;
  }
  .navbarX-inverse .navbarX-nav > li > a {
	color: #9d9d9d;
  }
  .navbarX-inverse .navbarX-nav > li > a:hover,
  .navbarX-inverse .navbarX-nav > li > a:focus {
	color: #fff;
	background-color: transparent;
  }
  .navbarX-inverse .navbarX-nav > .active > a,
  .navbarX-inverse .navbarX-nav > .active > a:hover,
  .navbarX-inverse .navbarX-nav > .active > a:focus {
	color: #fff;
	background-color: #080808;
  }
  .navbarX-inverse .navbarX-nav > .disabled > a,
  .navbarX-inverse .navbarX-nav > .disabled > a:hover,
  .navbarX-inverse .navbarX-nav > .disabled > a:focus {
	color: #444;
	background-color: transparent;
  }
  .navbarX-inverse .navbarX-toggle {
	border-color: #333;
  }
  .navbarX-inverse .navbarX-toggle:hover,
  .navbarX-inverse .navbarX-toggle:focus {
	background-color: #333;
  }
  .navbarX-inverse .navbarX-toggle .icon-bar {
	background-color: #fff;
  }
  .navbarX-inverse .navbarX-collapse,
  .navbarX-inverse .navbarX-form {
	border-color: #101010;
  }
  .navbarX-inverse .navbarX-nav > .open > a,
  .navbarX-inverse .navbarX-nav > .open > a:hover,
  .navbarX-inverse .navbarX-nav > .open > a:focus {
	color: #fff;
	background-color: #080808;
  }
  @media (max-width: 767px) {
	.navbarX-inverse .navbarX-nav .open .dropdown-menu > .dropdown-header {
	  border-color: #080808;
	}
	.navbarX-inverse .navbarX-nav .open .dropdown-menu .divider {
	  background-color: #080808;
	}
	.navbarX-inverse .navbarX-nav .open .dropdown-menu > li > a {
	  color: #9d9d9d;
	}
	.navbarX-inverse .navbarX-nav .open .dropdown-menu > li > a:hover,
	.navbarX-inverse .navbarX-nav .open .dropdown-menu > li > a:focus {
	  color: #fff;
	  background-color: transparent;
	}
	.navbarX-inverse .navbarX-nav .open .dropdown-menu > .active > a,
	.navbarX-inverse .navbarX-nav .open .dropdown-menu > .active > a:hover,
	.navbarX-inverse .navbarX-nav .open .dropdown-menu > .active > a:focus {
	  color: #fff;
	  background-color: #080808;
	}
	.navbarX-inverse .navbarX-nav .open .dropdown-menu > .disabled > a,
	.navbarX-inverse .navbarX-nav .open .dropdown-menu > .disabled > a:hover,
	.navbarX-inverse .navbarX-nav .open .dropdown-menu > .disabled > a:focus {
	  color: #444;
	  background-color: transparent;
	}
  }
  .navbarX-inverse .navbarX-link {
	color: #9d9d9d;
  }
  .navbarX-inverse .navbarX-link:hover {
	color: #fff;
  }
  .navbarX-inverse .btn-link {
	color: #9d9d9d;
  }
  .navbarX-inverse .btn-link:hover,
  .navbarX-inverse .btn-link:focus {
	color: #fff;
  }
  .navbarX-inverse .btn-link[disabled]:hover,
  fieldset[disabled] .navbarX-inverse .btn-link:hover,
  .navbarX-inverse .btn-link[disabled]:focus,
  fieldset[disabled] .navbarX-inverse .btn-link:focus {
	color: #444;
  }

  [data-toggle="buttons"] > .btn input[type="radio"],
  [data-toggle="buttons"] > .btn-group > .btn input[type="radio"],
  [data-toggle="buttons"] > .btn input[type="checkbox"],
  [data-toggle="buttons"] > .btn-group > .btn input[type="checkbox"] {
	position: absolute;
	clip: rect(0, 0, 0, 0);
	pointer-events: none;
  }

.right-panel {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.message-list {
    flex: 1;
    min-width: 140px;
    overflow: auto;
}

.link-black {
    color: black;
}

.input-area {
    height: 50px;
    background: red;
}

.lang-badge {
	position: fixed;
    width: 36px;
    left: 10px;
    bottom: 10px;
    height: 36px;
    z-index: 100;
    display: table;
    vertical-align: middle;
    border-radius: 18px;
    background-color: white;
	border: 1px rgba(0,0,0,.4) solid;
	mask-image: -webkit-radial-gradient(white, black);
	-webkit-mask-image: -webkit-radial-gradient(white, black);
}

.lang-badge-name {
	color: black;
    font-weight: 800;
    font-size: 14px;
    margin: 8px 0px;
	text-align: center;
}

.lang-choice {
	position: fixed;
    width: 120px;
    left: 10px;
    bottom: 10px;
    height: 100px;
}

.lang-choice-item {
	width: 120px;
    height: 32px;
    line-height: 32px;
    border-radius: 8px;
    text-align: center;
    border: 1px solid rgb(51, 51, 51);
    color: black;
    background-color: rgba(255, 255, 255, 0.8);
    margin: 2px 0px;
}